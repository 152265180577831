import React from 'react';
import PropTypes from 'prop-types';
import { FloatingNav } from '../../../layout/components/FloatingNav';
import { HeroImage } from '../../../components/heroes/HeroImage';
import { HeroInfo } from '../../../components/heroes/HeroInfo';
import { ImpactTitle, Paragraph } from '../../../components/ui/typography';
import { Footer } from '../../../layout/components/Footer';
import { DefaultHero } from '../../../components/heroes';
import { INFO_ICON, LOCATION_ICON, MUSIC_ICON, TICKET_ICON } from '../../../js/icons';
import TicketSection from '../sections/TicketSection';
import { DescriptionSection, OrganiserSection, VenueSection, VideoSection } from './PrimaryEventPage/components';
import ArtistsSections from './PrimaryEventPage/components/ArtistsSections';
import SponsorsSection from './PrimaryEventPage/components/SponsorsSection';
import { BsBasket } from "react-icons/bs";
import TopNavigation from "../../../layout/components/TopNavigation.jsx";
import Dates from "../../bookings/components/Dates.jsx";
import { ImageComponent } from "../../../components/ui/images/ImageComponent.jsx";
import { CDN_URL } from "../../../js/Helper.js";

const PrimaryEventPage = ({
  event, business, venue, tickets, djs_artists, sponsors, tables, hasDates
}) => {

    let heroInfo = [];
    let eventOrganiserLogo;
    let videos;
    let logo;


    try {
        let options = [];

        /**
         * Setting variables and ui for the hero
         * TODO - this is all a little messy and could do with being cleaned up.
         */

        let dateString = event.dates && `${event.dates.startDate} ${hasDates ? '' : 'at' + event.dates.startTime}`;


        const date = event.dates &&  {'value' : [<>📆</>, <span>{dateString}</span>]};
        // const location = event.location || event.secret_location && {'value' : [<>📍</>, <span><span style={{fontWeight:'bold'}}>{ event.secret_location ? 'Secret Location' : `${event.location.name}`}</span>{``}</span>]};
        const dressCode = event.dress_code && {'value' : [<>👚</>, <span>{event?.dress_code}</span>]};
        const location = event.location && {'value' : [<>📍</>, <span>{event?.location?.name}</span>]};
        // const shortDesc = event.short_description && {'value' : [<>🎟️</>, <span>{event?.short_description}</span>]};

        options.push([date, location, dressCode]);

        options.flat().forEach(element => {
          element && heroInfo.push(element)
        });

        eventOrganiserLogo = event?.business?.files && event?.business?.files.find((fileLink) => fileLink.name == 'brand_logo');
        videos = event?.files ? event?.files.filter((fileLink) => fileLink?.category === 'video') : [];
        logo = event?.files ? event?.files?.filter((fileLink) => fileLink?.name === 'logo') : [];


    } catch (e) {
        console.error('Error setting hero options', e)
    }

    const menuItems = []

    try {

      const musicMenu = {name: 'Music',destination: `${window.location.pathname}?section=featured`, icon: MUSIC_ICON, position: 1}

      const InfoItem =  {
        name: 'Info',
        destination: `${window.location.pathname}?section=information`,
        icon: INFO_ICON,
        position: 2,
      }

      const locationItem = {
        name: 'Location',
        destination: `${window.location.pathname}?section=location`,
        icon: LOCATION_ICON,
        position: 3,
      }

      const ticketItem =  {
        name: 'Tickets',
        destination: `${window.location.pathname}?section=tickets`,
        icon: TICKET_ICON,
        position: 4,
      }

      const menu =  {
        name: 'Menu',
        destination: `menu`,
        icon: 'IoMenuSharp',
        position: 5,
      }

      const basket =  {
        name: 'Basket',
        destination: `basket`,
        icon: 'BsBasket',
        position: 5,
      }
      //
      if(djs_artists?.length > 0) {
        menuItems.push(musicMenu)
      }

      if(event.description) {
        menuItems.push(InfoItem)
      }

      if(event.location) {
        menuItems.push(locationItem)
      }

      if(tickets?.length > 0) {
        menuItems.push(ticketItem)
      }


      menuItems.push(menu)

    } catch (e) {
        console.error('Error setting menu items', e)
    }



    
  return (
        <div>

            <TopNavigation />

            <DefaultHero
            image={<HeroImage image={`${event?.image?.cdnUrl}`} title={`Poster image for ${event?.business_name} - ${event.name} ~ Nocturnal Lifestyle`} alt={`Poster image for ${event?.business_name} - ${event.name} ~ Nocturnal Lifestyle`}/>}
            info={<HeroInfo data={heroInfo}/>}
            title={<ImpactTitle header={business?.name} subHeader={'presents'} main={event?.name}/>}
            description={<Paragraph id='description' style={{margin: '20px 0px', fontWeight:'normal'}}>{event.short_description}</Paragraph>}
            />
            
            {/*<SponsorsSection sponsors={djs_artists} />*/}

            { djs_artists?.length > 0 && <ArtistsSections artists={djs_artists} /> }
            { event?.description && <DescriptionSection /> }

            {
                event.business_id === 114 && (
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            gap: '20px',
                            padding: "20px",
                            paddingBottom: '0px',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <ImageComponent src={CDN_URL + '/images/da_rum-bar-logo-black-yellow-green-transparent.png'}
                                        width={80}
                                        style={{backgroundColor: 'white', borderRadius: '10px', height: '100px', padding: '10px'}}

                        />
                        <ImageComponent
                            style={{backgroundColor: 'white', borderRadius: '10px', height: '100px', padding: '10px'}}

                            src={CDN_URL + '/images/NEW LOGO_ 05 Four Roses_Logo 5 - Black Preferred (2).jpg'}
                            width={80}/>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            gap: '20px',
                            padding: "20px",
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <ImageComponent
                                style={{backgroundColor: 'white', borderRadius: '10px', height: '100px', padding: '10px'}}
                                src={CDN_URL + '/images/C Negro.png'} width={80}/>
                            <ImageComponent
                                style={{backgroundColor: 'white', borderRadius: '10px', height: '100px', padding: '15px'}}
                                src={CDN_URL + '/images/Titos-Std-Logo_Standard (1).png'} width={80}/>


                        </div>

                        <StandardSection style={{margin: '0px'}}>
                            <Paragraph><a target="_blank" rel="noopener noreferrer" href={'https://www.titosvodka.com/'}>https://www.titosvodka.com/</a> </Paragraph>
                            <Paragraph><a target="_blank" rel="noopener noreferrer" href={'https://www.dontmentionfourroses.com/'}>https://www.dontmentionfourroses.com/</a> </Paragraph>
                        </StandardSection>

                    </>

                )
            }


            <VenueSection venue={venue}/>
            <VideoSection videos={videos}/>

            <TicketSection hasDates={hasDates} tickets={tickets} tables={tables} event={event}/>


            {/* <OrganiserSection logo={eventOrganiserLogo} /> */}
            <FloatingNav items={menuItems}/>
            <Footer/>

        </div>
  )
}

export default PrimaryEventPage

PrimaryEventPage.propTypes = {
    // event: object,
    // business: object,
    // tickets: array,
    // sponsors: array,
    // tables: array,
    // venue: object,
    // djs_artists: array,
    // eventOrganiserLogo: string
}