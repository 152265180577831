import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {useLocation, useParams, useSearchParams} from 'react-router-dom';

// Layout and UI components
import { FullPageAbsoluteContainer } from "../../../layout/styles/layoutStyles.js";
import SliderComponent from "../../../components/ui/SliderComponent.jsx";
import { FloatingNav } from "../../../layout/components/FloatingNav/index.jsx";
import { ImpactTitle, InstructionTitle, Paragraph } from "../../../components/ui/typography/index.js";
import { ButtonComponent } from "../../../components/ui/buttons/index.js";
import { Footer } from "../../../layout/components/Footer.jsx";
import { Divider } from '../../../components/ui/Divider.jsx';
import TopNavigation from "../../../layout/components/TopNavigation.jsx";

// Icons
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { IoInformationCircleOutline, IoGiftOutline } from "react-icons/io5";

// Hooks and contexts
import { useApiQuery } from "../../../hooks/customHooks.js";
import { useDrawer } from '../../../js/hooks/context/DrawerContext.jsx';
import { useEcommerce } from "../../ecommerce/js/context/EcommerceContext.jsx";
import { useBasket } from "../../ecommerce/basket/hooks/useBasket.jsx";
import useHandleRemoveOrderItem from '../../ecommerce/js/hooks/useHandleRemoveOrderItem.js';

// Utility functions
import { convertToCurrency } from "../../../js/helpers/helpers.js";
import { scrollToElement } from "../../../js/Helper.js";
import { makeProduct } from "../../ecommerce/products/js/utils/productUtils.js";

// Components
import ProductCollection from '../../collections/components/ProductCollection.jsx';
import {extractColorCodeFromCssVariable, updateTheme} from "../../themes/js/utils.js";
import {ImageComponent} from "../../../components/ui/images/ImageComponent.jsx";
import {IntroSection} from "../../proposals/pages/IntroSection.jsx";
import {filterPartnersByRoles, filterProductsByCategory} from "../../events/js/utils.js";
import {PRODUCT_TABLE, PRODUCT_TICKET} from "../../events/js/constants.js";
import {eventData} from "../../events/js/eventData.js";
import Event from "../../events/index.js";
import PrimaryEventPage from "../../events/pages/PrimaryEventPage.jsx";
import {useEvent} from "../../events/js/hooks/index.js";
import { Section } from '../../../layout/components/LayoutSection.jsx';
import zIndex from '@mui/material/styles/zIndex.js';
import { HeroImage } from '../../../components/heroes/HeroImage.jsx';
import { Loading } from '../../../components/ui/Loading.jsx';
import EventsSection from '../../proposals/components/EventsSection.jsx';
import HtmlParagraph from "../../../components/ui/typography/HtmlParagraph.jsx";
import {VideoComponent} from "../../../components/ui/VideoComponent.jsx";

// Styled components
// Styled components
const Container = styled.div`
    height: 100%;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
    position: relative;

    @media (min-width: 768px) {
        scroll-snap-type: y proximity;
    }
`;

const PageContentContainer = styled.section`

    max-width: 100vw;
    
    @media (min-width: 768px) {
        margin: 0 auto;
        width: 80vw;
    }
`

const SectionContainer = styled(Section)`
    height: fit-content;
    min-height: fit-content;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
    background-size: cover;
    background-position: center;
    
    &:last-child {
        //min-height: calc(100vh - var(--footer-height, 0px));
    }

    strong {
        color: var(--accent-colour);
    }

    @media (min-width: 768px) {
        height: auto;
        min-height: 100vh;
        scroll-snap-align: none;
        padding: 40px;
    }

    @media (min-width: 1024px) {
        padding: 60px;
    }
`;

const ExposurePlatformContainer = styled.section`

    max-width: 100dvw;
    padding: 20px;
    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
    }
`

const ColumnContainer = styled.section`
    display: flex;
    
    
    @media (min-width: 768px) {
        flex-direction: row;
    }
    
    border: red solid 1px;
`

// Add a new styled component for the content wrapper
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    gap: 20px;
    max-height: fit-content; // Subtract padding to ensure content fits
    // overflow-y: auto; // Allow scrolling within sections if content overflows

    @media (min-width: 1024px) {
        max-width: var(--secondBreakMaxWidth);
    }
`;

export const MAIN_IMAGE = 'MainImage';
export const MAIN_VIDEO = 'MainVideo';
export const BACKGROUND_IMAGE = 'BackgroundImage';
export const CAMPAIGN_COLLECTION = 'influencer_deliverables';

const minFollowers = 275000;
const tikTokMinFollowers = 275000;
const maxFollowers = 25000000;
const minCostPerEngagedUser = 0.01; // 1p
const maxCostPerEngagedUser = 0.10; // 10p
const DEFAULT_INSTAGRAM_ENGAGEMENT_RATE = 0.02; // 3%
const DEFAULT_TIKTOK_ENGAGEMENT_RATE = 0.04; // 5%
const TIKTOK = "TikTok";
const INSTAGRAM = "Instagram";
const CampaignPurchaseLandingPage = () => {
    // Constants


    // State
    const [instagramFollowers, setInstagramFollowers] = useState(minFollowers);
    const [tiktokFollowers, setTiktokFollowers] = useState(minFollowers);
    const [costPerEngagedUser, setCostPerEngagedUser] = useState(0.025); // Default to 2.5p

    // Hooks
    const location = useLocation();
    const { setEvent } = useEvent();
    const { openDrawer } = useDrawer();
    const { addToBasket, basket } = useEcommerce();
    const { open } = useBasket();
    const handleRemoveOrderItem = useHandleRemoveOrderItem();
    const params = useParams();
    const slug = params.slug;
    const [campaign, setCampaign] = useState();
    const [instagramMetrics, setInstagramMetrics] = useState([]);
    const [tiktokMetrics, setTiktokMetrics] = useState([]);
    const [momentsMetrics, setMomentsMetrics] = useState([]);

    // Effects
    useEffect(() => {
        const updateFooterHeight = () => {
            const footerHeight = document.querySelector('footer')?.offsetHeight || 0;
            document.documentElement.style.setProperty('--footer-height', `${footerHeight}px`);
        };

        updateFooterHeight();
        window.addEventListener('resize', updateFooterHeight);

        return () => {
            window.removeEventListener('resize', updateFooterHeight);
            document.documentElement.style.removeProperty('--footer-height');
        };
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const section = searchParams.get('section');
        if (section) {
            scrollToElement(section);
        }
    }, [location.search]);


    /**
     * Get platform metrics 
     */

    useApiQuery(`platform/${INSTAGRAM}/metrics`, 
        (data) => {
            console.log('instagram metrics', data)
            setInstagramMetrics(data)
        },
        (error) => console.error('Error fetching Instagram metrics:', error),
        { retry: false }
    );

    useApiQuery(`platform/${TIKTOK}/metrics`, 
        (data) => {
            console.log('tiktok metrics', data)
            setTiktokMetrics(data)
        },
        (error) => console.error('Error fetching TikTok metrics:', error),
        { retry: false }
    );

    useApiQuery(`platform/moments/metrics`, 
        (data) => {
            console.log('moments metrics', data)
            setMomentsMetrics(data)
            const metric = data.find(metric => metric.metric === 'cost_per_engaged_user')
            const value = metric?.value ?? 0.025
            setCostPerEngagedUser(parseFloat(value.toFixed(3)))
        },
        (error) => console.error('Error fetching Moments metrics:', error),
        { retry: false }
    );

    /**
     * Get campaign data
     */

    useApiQuery(`campaigns/${slug}?with=fileLinks,catalogueLinks.catalogue,theme,events`, (data) => {
        console.log('data', data)
        if(data.status !== 'success') console.log('error')

        const res = data.data;
        setCampaign(res)
        updateTheme(res.theme)
    });




    const instagramEngagementRate = parseFloat((instagramMetrics.find(metric => metric.metric === 'engagement_rate')?.value || DEFAULT_INSTAGRAM_ENGAGEMENT_RATE).toFixed(3));
    const tiktokEngagementRate = parseFloat((tiktokMetrics.find(metric => metric.metric === 'engagement_rate')?.value || DEFAULT_TIKTOK_ENGAGEMENT_RATE).toFixed(3));



    // TODO  - Setup is Loading
    // TODO - setup is error

    /**
     *
     * @param followers
     * @param engagementRate
     * @returns {{followers, engagedUsers: number, budget: number}}
     */

        // Memoized calculations
    const calculateStats = (followers, engagementRate) => {
            const engagedUsers = Math.floor(followers * engagementRate);
            const budget = engagedUsers * costPerEngagedUser;
            return { followers, budget, engagedUsers };
        };

    const instagramStats = useMemo(() => calculateStats(instagramFollowers, instagramEngagementRate), [instagramFollowers, costPerEngagedUser]);
    const tiktokStats = useMemo(() => calculateStats(tiktokFollowers, tiktokEngagementRate), [tiktokFollowers, costPerEngagedUser]);

    // Formatted values
    const igEngagedUsers = instagramStats.engagedUsers.toLocaleString();
    const tikTokEngagedUsers = tiktokStats.engagedUsers.toLocaleString();
    const suffix = ' engaged users '
    const prefix = 'Sponsorship exposed to ';

    const instagramProductName = `${prefix} ${igEngagedUsers} ${suffix} on ${INSTAGRAM}`;
    const tikTokProductName = `${prefix} ${tikTokEngagedUsers} ${suffix} on ${TIKTOK}`;

    // Platform data mapping
    const platformMap = {
        TikTok: {
            productName: tikTokProductName,
            name: TIKTOK,
            engagedUsers: tikTokEngagedUsers,
            stats: tiktokStats
        },
        Instagram: {
            productName: instagramProductName,
            name: INSTAGRAM,
            engagedUsers: igEngagedUsers,
            stats: instagramStats
        }
    };

    // Event handlers
    const handleInstagramFollowersChange = (newValue) => setInstagramFollowers(newValue);
    const handleTikTokFollowersChange = (newValue) => setTiktokFollowers(newValue);
    const handleCostPerEngagedUserChange = (newValue) => setCostPerEngagedUser(newValue);

    if(!campaign) return <Loading />


    /**
     * Temp setup for getting the collection id from the request above
     * Will be good to pattern a cleaner way for this
     * May need to change how the data is transferred
     */


    const collection = campaign?.collection.find(collection => collection.catalogue.name === CAMPAIGN_COLLECTION).catalogue;
    const files = campaign.files;
    const events = campaign.events;
    const coverImage = null;
    const mainImage = files.find((file) => file.name === MAIN_IMAGE);
    const mainVideo = files.find((file) => file.name === MAIN_VIDEO);
    const backgroundImage = files.find((file) => file.name === BACKGROUND_IMAGE);




    const handleCampaignInfo = () => {
        openDrawer(
            <Paragraph style={{padding: '20px'}}>
                Our calculations are based on a cost of {(costPerEngagedUser * 100).toFixed(1)}p per engaged user.
                The engagement rates used are 1% for Instagram and 3% for TikTok, based on recent industry data. 
                These figures are averages and actual results may vary based on content quality, audience, and platform algorithms.
                The total cost is calculated by multiplying the number of engaged users by the cost per engaged user.
            </Paragraph>
        );
    };

    const handleAddToBasket = (platform) => {
        const selectedPlatform = platformMap[platform];

        const priceInCents = Math.round(selectedPlatform.stats.budget * 100);
        const product = makeProduct({
            name: selectedPlatform.productName,
            price: priceInCents,
            sub_total: priceInCents,
            quantity: 1,
            category: 'exposure',
            description: platform,
            business_id: campaign.business_id,
        });

        const isInBasket = basket.orderItems.find((item) => item.description === platform);

        if (isInBasket) {
            handleRemoveOrderItem(null, basket.orderItems, isInBasket);
        }

        addToBasket(product, 1);
        open();
    };

    /**
     * Get product collection
     */



    // Navigation items
    const floatingNavItems = [
        { name: 'Info', destination: `${window.location.pathname}?section=info`, icon: 'IoInformationCircleOutline', position: 1 },
        { name: 'Instagram', destination: `${window.location.pathname}?section=instagram`, icon: 'FaInstagram', position: 2 },
        { name: 'TikTok', destination: `${window.location.pathname}?section=tiktok`, icon: 'FaTiktok', position: 3 },
        { name: 'Deliverables', destination: `${window.location.pathname}?section=deliverables`, icon: 'IoGiftOutline', position: 4 }
    ];

    const hostedBy = {
        'bmf-campaign': 'Lil Meech',
        'clermont-twins-campaign': 'Clermont Twins',
    }


    // Render
    return (
        <Container>
            <TopNavigation />

            <SectionContainer 
                style={{padding: '0px', display: 'block'}} 
                id={'intro'} 
                // backgroundImage={campaign.coverImage || 'https://i.scdn.co/image/ab677762000056b8f5dc9a6d9300bd0122398247'}
            >
                <IntroSection
                    // logo={'/loud-logo.png'}
                    title={campaign.name}
                    subtitle={'SPONSOR THE'}
                    guestRole={'Hosted By'}
                    guest={hostedBy[slug]}
                    backgroundImage={backgroundImage.cdnUrl}
                />
            </SectionContainer>

            <PageContentContainer>



            <SectionContainer id={'info'}
                              style={{
                                  justifyContent: 'center',
                                  gap: '20px',
                                  minHeight: 'fit-content',
                                  height: 'fit-content',
                                }}>
                <ContentWrapper>
                    <ImageComponent src={mainImage.cdnUrl} width={innerWidth > 768 ? '450px' : '100%' } />
                    <ImpactTitle main={campaign.name} />
                    <HtmlParagraph text={campaign.description} style={{color: 'var(--text-colour)', width: innerWidth > 768 ? '600px' : '100%'}} />
                    <VideoComponent data={mainVideo} />

                    {events?.length > 0 && (
                        <EventsSection events={events} />
                    )}
                </ContentWrapper>
            </SectionContainer>



            {/* <SectionContainer id="cost-settings">
                <ContentWrapper>
                    <ImpactTitle main="Cost Settings" />
                    <Paragraph>Adjust the cost per engaged user: This is only for us to see and will be removed before launch.</Paragraph>
                    <Paragraph>This slider affects the Instagram & TikTok calculations below by changing the pence per engaged user.</Paragraph>
                    <SliderComponent
                        type="number"
                        value={costPerEngagedUser}
                        min={minCostPerEngagedUser}
                        max={maxCostPerEngagedUser}
                        step={0.001}
                        onChange={handleCostPerEngagedUserChange}
                    />
                    <Paragraph>
                        Current cost per engaged user: <strong>{(costPerEngagedUser * 100).toFixed(2)}p</strong>
                    </Paragraph>
                </ContentWrapper>
            </SectionContainer> */}

            {/* exposure explanation */}
            <SectionContainer id={'exposure-explanation'}>
                <ContentWrapper>
                    <ImpactTitle main="Exposure" />
                    <Paragraph>
                        Exposure in this campaign refers to the potential reach and engagement your brand can achieve through social media platforms.
                    </Paragraph>
                    <Paragraph>
                        By adjusting the follower count for Instagram and TikTok, you can estimate the number of engaged users and the associated cost. This allows you to tailor your investment based on your target audience size and budget.
                    </Paragraph>
                    <Paragraph>
                        Remember, higher follower counts generally lead to more engagement, but also increase the campaign cost. Use the sliders below to find the right balance for your marketing goals.
                    </Paragraph>
                </ContentWrapper>
            </SectionContainer>



            <ExposurePlatformContainer id={'exposure-platform-container'}>
              <CampaignSection
                platform={INSTAGRAM}
                followers={instagramFollowers}
                maxFollowers={maxFollowers}
                minFollowers={minFollowers}
                costPerEngagedUser={costPerEngagedUser}
                handleCampaignInfo={handleCampaignInfo}
                engagementRate={instagramEngagementRate}
                stats={instagramStats}
                handleFollowersChange={handleInstagramFollowersChange}
                handleAddToBasket={handleAddToBasket}
              />
              <CampaignSection
                platform={TIKTOK}
                costPerEngagedUser={costPerEngagedUser}
                followers={tiktokFollowers}
                maxFollowers={maxFollowers}
                handleCampaignInfo={handleCampaignInfo}
                minFollowers={tikTokMinFollowers}
                engagementRate={tiktokEngagementRate}
                stats={tiktokStats}
                handleFollowersChange={handleTikTokFollowersChange}
                handleAddToBasket={handleAddToBasket}
              />
            </ExposurePlatformContainer>


                {/* Deliverables Section */}
            <Section id={'deliverables'} clip={"true"} style={{
                background: 'var(--ticket-section-bg-colour)', 
                zIndex: 1, 
                paddingBottom: '250px', 
                paddingTop: '100px',
                height: 'fit-content'
            }}>
                <ContentWrapper>
                    <ImpactTitle main={'Deliverables'} style={{main: { backgroundClip: 'none', color: 'transparent'}}} />
                    <Paragraph>
                        Below is a list of deliverables. Choose the ones that best suits your marketing goals needs. 
                    </Paragraph>
                    {<ProductCollection data={collection} />}
                </ContentWrapper>
            </Section>

            </PageContentContainer>


            <FloatingNav items={floatingNavItems} />
        </Container>
    );
};

const CampaignSection = ({ platform, followers, maxFollowers, minFollowers, engagementRate, stats, costPerEngagedUser, handleFollowersChange, handleAddToBasket, handleCampaignInfo }) => (
    <SectionContainer id={platform.toLowerCase()} style={{maxWidth: '800px', padding: '20px', borderRadius: 'var(--border-radius-large)', marginTop: '20px', height: '620px',border: `solid 1px ${extractColorCodeFromCssVariable('--accent-colour')}`}}>
        <ContentWrapper style={{ padding: '20px 0px'}}>
            <ImpactTitle subHeader={platform === INSTAGRAM ? <FaInstagram size={48} color="#C13584" /> : <FaTiktok size={48} color="white" />} main={`${platform} Campaign`} />
            <div style={{ color: 'var(--text-colour)' }}>
                <SliderComponent
                    suffix={' followers'}
                    type={'number'}
                    value={followers}
                    max={maxFollowers}
                    min={minFollowers}
                    step={100000}
                    onChange={handleFollowersChange}
                />
                <Paragraph style={{width: '100%',marginBottom: '15px'}} >
                    With a {platform} reach of <strong>{followers.toLocaleString()}</strong> followers:
                </Paragraph>
                <ul>
                    <li>Engagement rate: <strong>{(engagementRate * 100).toFixed(0)}%</strong></li>
                    <li>Estimated engaged users: <strong>{stats.engagedUsers.toLocaleString()}</strong></li>
                    <li>Cost per engaged user: <strong>{(costPerEngagedUser * 100).toFixed(1)}p</strong></li>
                    <li>Campaign cost: <strong>£{stats.budget.toLocaleString()}</strong></li>
                </ul>
                <br />
                <Paragraph onClick={handleCampaignInfo}  style={{width: '100%'}}>
                    Read More
                </Paragraph>
            </div>
            <ButtonComponent
                variant={'outlined'}
                text={`£${stats.budget.toLocaleString()} - Add To Basket`}
                onClick={() => handleAddToBasket(platform)}
            />
        </ContentWrapper>
    </SectionContainer>
);

CampaignPurchaseLandingPage.propTypes = {};

export default CampaignPurchaseLandingPage;
