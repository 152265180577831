import React, {useEffect} from 'react';
import StripePaymentForm from "../../services/stripe/components/StripePaymentForm.jsx";
import styled from "styled-components";
import Section from "../../orders/components/Section.jsx";
import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";

const PaymentSectionContainer = styled.section`
    padding: 50px 0;
    width: 100%;
`
const PaymentSectionComponent = ({product, quantity = 1}) => {


    return (
        <PaymentSectionContainer>
            <StripePaymentForm    />
        </PaymentSectionContainer>
    );
};

export default PaymentSectionComponent;