/**
 * @fileoverview EcommerceContext provides global state management for the e-commerce feature.
 * It handles basket management, product addons, payment processing, and order management.
 * 
 * Key Features:
 * - Basket Management: Add/remove items, update quantities
 * - Addon Management: Handle product addons with parent-child relationships
 * - Payment Processing: Manage payment intents and checkout flow
 * - Persistence: Save/restore basket state to/from localStorage
 * - Server Sync: Keep local basket in sync with server
 */

import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import {useHandleError} from "../../../error-handling/js/hooks/index.js";
import {useEvent} from "../../../events/js/hooks/index.js";
import {orderItemSubTotal} from "../../basket/js/utils/basketUtils.js";
import axios from "axios";
import {API_URL, getSessionStorage} from "../../../../js/Helper.js";
import {generateUUID} from "../../../../js/utils/utils.js";
import {toast} from "react-toastify";
import {makeRequest} from "../../../../services/axios.js";
import {AuthContext} from "../../../authentication/index.js";

const EcommerceContext = createContext();

/**
 * Provider component for e-commerce functionality
 * @component
 * @param {Object} props
 * @param {React.ReactNode} props.children - Child components that will have access to the context
 */
export const EcommerceProvider = ({ children }) => {

  /**
   * Initialize basket from localStorage or create new
   * @type {Object} basket - Basket state
   * @type {string} basket.id - Unique basket ID
   * @type {string} basket.sessionId - Session ID associated with the basket
   * @type {Array} basket.orderItems - Array of order items in the basket
   * @type {string} basket.mode - Mode of the basket (e.g., 'combined')
   */
  const [basket, setBasket] = useState(() => {
    const savedBasket = localStorage.getItem('basket');
    return savedBasket
        ? JSON.parse(savedBasket)
        : {
          id: generateUUID(),
          sessionId: getSessionStorage('appSession')?.id,
          orderItems: [],
          mode: 'combined',
        };
  });


  /**
   * State for payment and configuration
   * @type {Object} paymentIntent - Payment intent state
   * @type {Object} config - Configuration state
   * @type {number} time - Timestamp state
   */
  const [paymentIntent, setPaymentIntent] = useState();
  const [config, setConfig] = useState();
  const [time, setTime] = useState()

  /**
   * Utility hooks
   * @type {Function} reportError - Error reporting function
   * @type {Object} event - Event handling object
   * @type {Object} header - Authentication header object
   */
  const { reportError } = useHandleError();
  const { event } = useEvent()
  const { header } = useContext(AuthContext)



  // Load saved basket from local storage
  useEffect(() => {
    const savedBasket = JSON.parse(localStorage.getItem('basket'));

    if(!savedBasket || savedBasket === 'undefined') {
      return;
    }

    axios.get(`${API_URL}/basket/${savedBasket?.id}/is-active`)
        .then((res) => {

          if(res.data.id === savedBasket?.id) {
            setBasket(JSON.stringify(res.data))
          }

        }).catch((err) => {
            setBasket( {
              id: generateUUID(),
              sessionId: getSessionStorage('appSession')?.id,
              orderItems: [],
              mode: 'combined',
            })
        })



    const savedConfig = sessionStorage.getItem('config');

    if (savedConfig !== 'undefined') {
      setConfig(JSON.parse(savedConfig));
    }

  }, []);

  useEffect(() => {
    sessionStorage.setItem('config', JSON.stringify(config));
  }, [config])

  // Save basket to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('basket', JSON.stringify(basket));
  }, [basket]);

  /**
   * Add an addon to a product in the basket
   * @param {Object} addon - Addon to add
   * @param {Object} product - Product to add addon to
   */
  const addAddon = (addon, product) => {
    if (!addon || !product) {
        console.error('Invalid addon or product');
        return;
    }

    // Validate addon quantity against parent product
    const addonQuantity = Math.min(addon.quantity || product.quantity, product.quantity);
    
    // Prepare addon with validated quantity
    const preparedAddon = {
        ...addon,
        quantity: addonQuantity,
        parentProductId: product.id,
        unit_price: addon.price,
        sub_total: addon.price * addonQuantity
    };

    const updatedBasketItems = basketItems().map(basketItem => {
        if (basketItem.id === product.id) {
            // Check if addons array exists, if not, initialize it
            const existingAddons = basketItem.addons || [];
            
            // Check if addon already exists
            const existingAddon = existingAddons.find(a => a.id === addon.id);
            if (!existingAddon) {
                return {
                    ...basketItem,
                    addons: [...existingAddons, preparedAddon]
                };
            }
        }
        return basketItem;
    });

    setOrderItems(updatedBasketItems);
  };

  /**
   * Update addon quantities when parent product quantity changes
   * @param {string} productId - ID of the parent product
   * @param {number} newQuantity - New quantity of the parent product
   */
  const updateAddonQuantities = (productId, newQuantity) => {
    const updatedBasketItems = basketItems().map(basketItem => {
        if (basketItem.id === productId && basketItem.addons?.length > 0) {
            // Update each addon's quantity proportionally
            const updatedAddons = basketItem.addons.map(addon => {
                // Calculate new addon quantity, not exceeding parent quantity
                const newAddonQuantity = Math.min(newQuantity, addon.quantity);
                
                return {
                    ...addon,
                    quantity: newAddonQuantity,
                    sub_total: addon.unit_price * newAddonQuantity
                };
            });

            return {
                ...basketItem,
                addons: updatedAddons
            };
        }
        return basketItem;
    });

    setOrderItems(updatedBasketItems);
  };

  /**
   * Update the quantity of a product or addon in the basket
   * @param {string} productId - ID of the product to update
   * @param {number} newQuantity - New quantity value
   */
  const updateQuantity = (productId, newQuantity) => {
    try {
        const updatedItems = updateOrderItemValue(productId, 'quantity', newQuantity);
        
        // Find the updated item to check if it's a main product
        const updatedItem = updatedItems.find(item => item.id === productId);
        
        // If it's a main product, update its addon quantities
        if (updatedItem && updatedItem.addons?.length > 0) {
            updateAddonQuantities(productId, newQuantity);
        }
        
        // If it's an addon, validate against parent product quantity
        if (!updatedItem) {
            const parentItem = updatedItems.find(item => 
                item.addons?.some(addon => addon.id === productId)
            );
            
            if (parentItem) {
                const addon = parentItem.addons.find(a => a.id === productId);
                if (addon && newQuantity > parentItem.quantity) {
                    // Reset to parent quantity if exceeding
                    updateOrderItemValue(productId, 'quantity', parentItem.quantity);
                    toast.warning('Addon quantity cannot exceed parent product quantity');
                }
            }
        }
    } catch (error) {
        console.error('Error updating quantity:', error);
        reportError(error);
    }
  };

  /**
   * Remove an addon from a product in the basket
   * @param {Object} addon - Addon to remove
   * @param {Object} product - Product to remove addon from
   */
  const removeAddon = (addon, product) => {
    if (!addon || !product) {
        console.error('Invalid addon or product');
        return;
    }

    // Map through basket items to find the product and update its addons array
    const updatedBasketItems = basketItems().map(basketItem => {
        if (basketItem.id === product.id && basketItem.addons) {
            // Filter out the addon from the product's addons array
            return {
                ...basketItem,
                addons: basketItem.addons.filter(a => a.id !== addon.id)
            };
        }
        return basketItem;
    });

    // Update the basket with the modified product
    setOrderItems(updatedBasketItems);

    // Update selected addons, removing only addons associated with this product
    setSelectedAddons(prev => prev.filter(a => 
        a.id !== addon.id || a.parentProductId !== product.id
    ));
  };

  /**
   * Remove an item from the basket
   * @param {Object} item - Item to remove
   */
  const removeFromBasket = async (item) => {
    try {
        if (!item || !item.id) {
            throw new Error('Invalid item to remove');
        }

        // Update local state immediately
        setBasket(prevBasket => ({
            ...prevBasket,
            orderItems: prevBasket.orderItems.filter(orderItem => {
                // Remove the main product and any products that depend on it
                const isMainProduct = orderItem.id === item.id;
                const isDependentProduct = orderItem.addons?.some(addon => addon.parentProductId === item.id);
                return !isMainProduct && !isDependentProduct;
            })
        }));

        // Sync with server
        try {
            const res = await makeRequest(
                'POST',
                `${API_URL}/basket/${basket.id}/remove-product/${item.id}`,
                null,
                header()
            );

            if (res?.basket) {
                setBasket(res.basket);
            }
        } catch (err) {
            console.error('Failed to sync removal with server:', err);
            // Could implement retry logic here if needed
        }

    } catch (e) {
        console.error('Error removing item from basket:', e);
        reportError(e);
        throw e;
    }
  };

  /**
   * Create an order item from a product
   * @param {Object} product - Product to create order item from
   * @returns {Object} orderItem - Created order item
   */
  const createOrderItem = (product) => {

    console.log('product', product)

    return {
      id: product.id,
      name: product.name,
      price: product.price,
      quantity: product.quantity,
      minQuantity: product.min_quantity,
      notices: product.basket_comment,
      numberOfGuests: product.numberOfGuests,
      fees: product.fees,
      addons: product.addons
    }
  }

  /**
   * Add a product to the basket
   * @param {Object} product - Product to add
   * @param {number} quantity - Quantity of product to add
   */
  const addToBasket = async (product, quantity) => {
    try {
        if (!product || !product.id) {
            throw new Error('Invalid product configuration');
        }

        // Check if product already exists in basket
        if (basket.orderItems.find(orderItem => orderItem.id === product.id)) {
            console.warn('Product already exists in basket');
            return;
        }

        // Prepare product with its addons
        const productToAdd = {
            ...product,
            quantity: quantity || product.min_quantity || 1,
            addons: (product.addons || []).map(addon => ({
                ...addon,
                quantity: quantity || product.min_quantity || 1,
                parentProductId: product.id
            }))
        };

        // Update local basket first for immediate feedback
        setBasket(prevBasket => ({
            ...prevBasket,
            orderItems: [...prevBasket.orderItems, productToAdd],
        }));

        // Sync with server
        try {
            const res = await makeRequest(
                'POST',
                `${API_URL}/basket/${basket.id}/add-product/${product.id}`,
                {
                    orderItem: productToAdd,
                    sessionId: basket.sessionId
                },
                header()
            );

            if (res?.basket) {
                setBasket(res.basket);
            }
        } catch (err) {
            console.error('Server sync failed:', err);
            
            // Revert local changes on server error
            setBasket(prevBasket => ({
                ...prevBasket,
                orderItems: prevBasket.orderItems.filter(item => item.id !== product.id)
            }));

            // Get the actual error message from the server response
            const errorMessage = err.response?.data?.message || err.response?.data?.error_message || 'Failed to sync with server';
            
            // Show error toast with the actual message
            // toast.error(errorMessage, {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            // });

            throw new Error(errorMessage);
        }

    } catch (e) {
        console.error('Error adding product to basket:', e);
        reportError(e);
        throw e;
    }
  };

  /**
   * Update the order items in the basket
   * @param {Array} newOrderItems - New order items to update with
   */
  const setOrderItems = (newOrderItems) => {
    if (Array.isArray(newOrderItems)) {
      setBasket(prevBasket => ({ ...prevBasket, orderItems: newOrderItems }));
    } else {
      console.warn('Tried to set orderItems with a non-array value:', newOrderItems);
    }
  };

  /**
   * Update the value of a property in an order item or its addon
   * @param {string} productId - ID of the product to update
   * @param {string} key - Key of the property to update
   * @param {*} newValue - New value for the property
   * @returns {Array} updatedItems - Updated order items
   */
  const updateOrderItemValue = (productId, key, newValue) => {
    let itemFound = false;


    const updatedItems = basket.orderItems.map(item => {
      console.log(item.id, productId)
      if (item.id === productId) {
        itemFound = true;
        return {
          ...item,
          [key]: newValue
        };
      } else if (item?.addons?.length > 0) {
        // Check addons array inside each order item
        const updatedAddons = item.addons.map(addon => {
          if (addon.id === productId) {
            itemFound = true;
            return {
              ...addon,
              [key]: newValue
            };
          }
          return addon;
        });

        return {
          ...item,
          addons: updatedAddons
        };
      }
      return item;
    });


    if (!itemFound) {
      console.warn(`Item with product_id ${productId} not found.`);
    }

    setOrderItems(updatedItems);

    return updatedItems;
  }

  /**
   * Get the basket items
   * @returns {Array} basketItems - Array of basket items
   */
  const basketItems = () => { return basket.orderItems }

  /**
   * Get all fees from the basket items and their addons.
   * @returns {Array} fees - Array of fees
   */
  const fees = () => {
    const items = basketItems();

    // Collect fees from main order items and their addons
    return items.flatMap(orderItem => {
      // Get fees for the main order item
      const orderItemFees = Array.isArray(orderItem.fees) ? orderItem.fees : [];

      // Get fees for the addons
      const addonFees = orderItem.addons && orderItem.addons.length > 0
          ? orderItem.addons.flatMap(addon => Array.isArray(addon.fees) ? addon.fees : [])
          : [];

      // Combine main order item fees and addon fees
      return [...orderItemFees, ...addonFees];
    });
  };

  /**
   * Get the grouped fees
   * @returns {Array} groupedFees - Array of grouped fees
   */
  const groupedFees = () => {
    const feesGroupedByName = fees().reduce((acc, fee) => {
      // Initialize the group if it doesn't exist
      if (!acc[fee.name]) {
        acc[fee.name] = {
          ...fee,
          amount: 0 // Initialize amount to 0
        };
      }

      // Add the fee's amount to the total for this group
      acc[fee.name].amount += fee.amount * fee.quantity;

      return acc;
    }, {});

    return Object.values(feesGroupedByName);
  }

  /**
   * Calculate the due today amount
   * @returns {number} dueToday - Due today amount
   */
  const dueToday = () => {
    let total = 0;


    basketItems()?.forEach(item => {
      // Calculate total for main product
      if (productAcceptsDeposit(item) && item.paymentOption === DEPOSIT_PAYMENT_OPTION) {
        total += item.depositAmount * item.quantity;
      } else {
        total += item.price * item.quantity;
      }

      // Calculate total for addons
      if (item.addons && item.addons.length > 0) {
        item.addons.forEach(addon => {
          if (productAcceptsDeposit(addon) && addon.paymentOption === DEPOSIT_PAYMENT_OPTION) {
            total += addon.depositAmount * addon.quantity;
          } else {
            total += addon.price * addon.quantity;
          }
        });
      }
    });

    return total + feeTotal();
  };

  // Ensure productAcceptsDeposit function is correctly implemented
  /**
   * Check if a product accepts deposit
   * @param {Object} product - Product to check
   * @returns {boolean} productAcceptsDeposit - Whether the product accepts deposit
   */
  const productAcceptsDeposit = (product) => {
    return product.depositAmount > 0;
  };

  // Ensure DEPOSIT_PAYMENT_OPTION is correctly defined
  const DEPOSIT_PAYMENT_OPTION = 'deposit';


  /**
   * Calculate the subtotal
   * @returns {number} subTotal - Subtotal amount
   */
  const subTotal = () => {
    let total = 0;

    basketItems().forEach(item => {

      // const depositAmount = calculateDeposit(item, item.quantity);
      // updateOrderItemValue(item.id, 'depositAmountIncAddons', depositAmount)

      // Calculate subtotal for the main item
      total += orderItemSubTotal(item)

      // Check and calculate subtotal for each addon if any
      if (item.addons && item.addons.length > 0) {
        item.addons.forEach(addon => {
          total += orderItemSubTotal(addon);
        });
      }
    });

    return total;
  };
  /**
   * Calculate the fee total
   * @returns {number} feeTotal - Fee total amount
   */
  const feeTotal = () => {
    let total = 0;


    fees().forEach(element => {
      total += (element?.amount * element?.quantity)
    });
    return total;
  }

  /**
   * Calculate the total
   * @returns {number} total - Total amount
   */
  const total = () => {
    return feeTotal() + subTotal()
  }

  /**
   * Calculate the total full price
   * @returns {number} totalFullPrice - Total full price amount
   */
  const totalFullPrice = () => {
      return basketItems().reduce((acc, item) => {
          return acc + item.price * item.quantity;
      }, 0);
  }


  /**
   * Update the configuration
   * @param {Object} data - Data to update the configuration with
   */
  const updateConfig = (data) => {

    if(typeof data !== 'object') {
      console.warn('Data type must be a nobject');
    }

    setConfig(prevConfig => ({ ...prevConfig, ...data }));
  }

  /**
   * Empty the basket
   */
  const emptyBasket = () => {
    setOrderItems([]);
  }

  const isInBasket = (productId) => {
    return basket.orderItems.some(item => item.productId === productId);
  }


  /**
   * Value object for the EcommerceContext
   * @type {Object} value - Value object
   */
  const value = {
    config,
    basket,
    paymentIntent,
    fees,
    time,
    total,
    dueToday,
    feeTotal,
    subTotal,
    totalFullPrice,
    setTime,
    setConfig,
    setBasket,
    addToBasket,
    addAddon,
    removeAddon,
    groupedFees,
    basketItems,
    isInBasket,
    updateConfig,
    setOrderItems,
    emptyBasket,
    updateOrderItemValue,
    setPaymentIntent,
    removeFromBasket,
    updateQuantity
  };

  return (
    <EcommerceContext.Provider value={value}>
      {children}
    </EcommerceContext.Provider>
  );
};


// Custom hook to use the EcommerceContext and get the `basket` and `addToBasket`
export const useEcommerce = () => {
  const context = useContext(EcommerceContext);
  
  if (!context) {
    throw new Error('useEcommerce must be used within a EcommerceProvider');
  }
  
  
  
  return context;
};


EcommerceProvider.propTypes = {
  children: PropTypes.object
};
