import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion';
import {useSwipeable} from 'react-swipeable';
import {Paragraph, ShortTitle} from "../../../components/ui/typography/index.js";
import {StandardSection} from '../../../layout/sections/StandardSection.jsx';
import {IntroSection} from '../../proposals/pages/IntroSection.jsx';
import {BsLightningCharge} from 'react-icons/bs';
import {VideoComponent} from '../../../components/ui/VideoComponent.jsx';
import PropTypes from 'prop-types';
import {useDrawer} from '../../../js/hooks/context/DrawerContext.jsx';
import {FloatingNav} from '../../../layout/components/FloatingNav/index.jsx';
import {SlideOutMenu} from '../../../layout/components/menus/index.js';
import Invest from "../../proposals/pages/Invest.jsx";
import {ListContainer, ListItemContent, ListItemTitle, StyledListItem} from "../../../components/Lists/list.styles.js";
import {ListItemComponent} from "../../../components/Lists/ListItemComponent.jsx";
import {slideUpFadeInVariant} from "../../../js/styles/animations.js";



const fadeInVariant = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3 }
};



const SHADOWS = {
    hover: "0 20px 40px rgba(0, 0, 0, 0.2)",
    light: "0 15px 30px rgba(0, 0, 0, 0.15)"
};

const Container = styled.div`
    color: var(--text-colour);
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
`;


const StatGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin: 40px 0;
    position: relative;
    
    &::before {
        content: '';
        position: absolute;
        inset: -20px;
        border-radius: var(--border-radius-default);
        // background: linear-gradient(45deg, rgba(255,255,255,0.05), transparent);
        z-index: 0;
    }
`;

const StatCard = styled(motion.div)`
    background: var(--glass-background);
    padding: 20px;
    border-radius: var(--border-radius-default);
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(10px);
    border: 1px solid var(--glass-border);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    
    @media (hover: hover) {
        &:hover {
            transform: translateY(-5px);
            background: var(--glass-background-hover);
            border-color: var(--glass-border-hover);
            //box-shadow: var(--glass-shadow-hover);
        }
    }
    
    &:active {
        transform: scale(0.98);
        background:var(--glass-background-hover);
        border-color: var(--glass-border-hover);
    }
`;

const StatValue = styled.div`
    font-size: 1.25rem;
    font-weight: 800;
    background: linear-gradient(135deg, var(--success-colour), var(--accent-colour));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 0.8;
`;

const StatLabel = styled.div`
    font-size: 1rem;
    opacity: 0.8;
    letter-spacing: 0.5px;
`;

const StyledParagraphContainer = styled(motion.div)`
    position: relative;
    background: var(--glass-background);
    backdrop-filter: blur(10px);
    border-radius: var(--border-radius-default);
    padding: 30px;
    margin: 20px 0;
    overflow: visible;

    /* Glass morphism effect without border */
    &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: var(--border-radius-default);
        background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.05),
            rgba(255, 255, 255, 0.02)
        );
        pointer-events: none;
    }

    /* Top-right decorative shape */
    &::after {
        content: '';
        position: absolute;
        top: -60px;
        right: -60px;
        width: 180px;
        height: 180px;
        background: linear-gradient(135deg, var(--success-colour), transparent);
        opacity: 0.08;
        transform: rotate(45deg);
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        filter: blur(2px);
        z-index: -1;
    }

    /* Bottom-left decorative shape */
    .decorative-shape {
        position: absolute;
        bottom: -40px;
        left: -40px;
        width: 160px;
        height: 160px;
        background: linear-gradient(315deg, var(--accent-colour), transparent);
        opacity: 0.08;
        transform: rotate(-30deg);
        clip-path: polygon(0 0, 100% 100%, 0 100%);
        filter: blur(2px);
        z-index: -1;
    }
`;

const StyledParagraph = styled(Paragraph)`
    color: var(--text-colour);
    line-height: 1.6;
    position: relative;
    z-index: 1;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const ProgressBar = styled.div`
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(var(--accent-colour-rgb), 0.1);
    backdrop-filter: blur(10px);
    border-radius: 30px;
    padding: 5px;
    margin-bottom: 80px;
    display: flex;
    gap: 8px;
    z-index: 99;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const ProgressDot = styled.div`
    width: ${props => props.active ? '25px' : '8px'};
    height: 8px;
    border-radius: 30px;
    background-color: ${props => props.active ? 'var(--accent-colour)' : 'rgba(255,255,255,0.3)'};
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;

    &:hover {
        background-color: ${props => props.active ? 'var(--accent-colour)' : 'rgba(255,255,255,0.5)'};
    }
`;

const DragScrollIndicator = styled(motion.div)`
    display: none;
    
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        color: var(--text-colour);
        opacity: 0.7;
        font-size: 0.8rem;
        
        &:before {
            content: '←';
            margin-right: 5px;
        }
        
        &:after {
            content: '→';
            margin-left: 5px;
        }
    }
`;

const Section = styled(StandardSection)`
    position: relative;
    scroll-snap-align: start;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    overflow-x: hidden;
`;

const ContentWrapper = styled.div`
    padding: 0 20px;
    
    @media (max-width: 768px) {
        padding: 0 20px;
    }
`;



const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            stiffness: 300,
            damping: 30
        }
    },
    pressed: {
        scale: 0.98,
        backgroundColor: "rgba(255, 255, 255, 0.07)",
        transition: { duration: 0.2 }
    }
};



const SectionTemplate = ({ id, title, subtitle, description, components }) => (
    <Section
        name='section'
        id={id}
        as={motion.section}
        {...fadeInVariant}
        style={{ justifyContent: 'space-around' }}
    >
            {(title || subtitle) && <ShortTitle title={title} subtitle={subtitle} />}
            {description && (
                <StyledParagraphContainer>
                    <div className="decorative-shape" />
                    <StyledParagraph>{description}</StyledParagraph>
                </StyledParagraphContainer>
            )}
            {components?.map((component, index) => {
                switch (component.type) {
                    case 'list':
                        return (
                            <ListContainer key={index} {...slideUpFadeInVariant}>
                                {component.items.map((item, idx) => (
                                    <ListItemComponent
                                        key={idx}
                                        {...item}
                                        isFirst={idx === 0}
                                        isLast={idx === component.items.length - 1}
                                    />
                                ))}
                            </ListContainer>
                        );
                    case 'stats':
                        return (
                            <StatGrid key={index}>
                                {component.items.map((stat, idx) => (
                                    <StatCard
                                        key={idx}
                                        as={motion.div}
                                        variants={cardVariants}
                                        initial="hidden"
                                        whileInView="visible"
                                        whileTap="pressed"
                                        viewport={{ once: true }}
                                    >
                                        <StatValue>{stat.value}</StatValue>
                                        <StatLabel>{stat.label}</StatLabel>
                                    </StatCard>
                                ))}
                            </StatGrid>
                        );
                    case 'subtitle':
                        return <ShortTitle key={index} subtitle={component.text} />;
                    case 'paragraph':
                        return (
                            <StyledParagraphContainer key={index}>
                                <div className="decorative-shape" />
                                <StyledParagraph>{component.text}</StyledParagraph>
                            </StyledParagraphContainer>
                        );
                    default:
                        return null;
                }
            })}
    </Section>
);

const sections = [
    {
        id: 'proposal',
        title: 'Why',
        subtitle: 'We Exist',
        description: 'We exist to redefine how businesses and consumers connect, transact, and thrive. In a world where experiences are often impersonal and disconnected, Nocturnal Lifestyle emerges as a transformative force—a Commerce Experience Engine designed to elevate every customer interaction.',
        components: [
            {
                type: 'list',
                items: [
                    { title: "Empower businesses", content: "Drive revenue growth and operational efficiency" },
                    { title: "Revolutionize Consumer Engagement", content: "Through personalized, seamless experiences" },
                    { title: "Foster Collaboration", content: "Sparking innovation and creating a dynamic ecosystem" }
                ]
            }
        ]
    },
    {
        id: 'what-we-offer',
        title: 'What',
        subtitle: 'We Offer',
        description: 'Nocturnal Lifestyle is not just a platform; it\'s a next-gen Commerce Experience Engine:',
        components: [
            {
                type: 'list',
                items: [
                    { title: "Event Management", content: "Tools for ticketing, promotions, and engagement" },
                    { title: "Dynamic Loyalty Programs", content: "Rewards that foster engagement and repeat business", buttonText: 'Read More', onButtonClick: () => console.log('Read More') },
                    { title: "Seamless Transactions", content: "Frictionless payment solutions that integrate effortlessly" },
                    { title: "Collaborative Networks", content: "Connecting businesses to shared audiences for mutual growth" },
                    { title: "Data-Driven Insights", content: "Turning data into actionable strategies for better decisions" }
                ]
            }
        ]
    },
    {
        id: 'how-we-work',
        title: 'How',
        subtitle: 'We Work',
        description: 'Our approach combines cutting-edge technology with user-centric design:',
        components: [
            {
                type: 'list',
                items: [
                    { title: "User-Centric Design", content: "Intuitive interfaces that prioritize user experience" },
                    { title: "Scalable Architecture", content: "Built to grow with your business needs" },
                    { title: "Continuous Innovation", content: "Regular updates and new feature rollouts" },
                    { title: "Security First", content: "Enterprise-grade security at every level" }
                ]
            }
        ]
    },
    {
        id: 'industry-experience',
        title: 'Industry',
        subtitle: 'Experience',
        components: [
            {
                type: 'list',
                items: [
                    { title: "Industry Experience", content: "10+ years of industry experience, from event management to customer engagement" },
                    { title: "Dedicated Team", content: "A dedicated in-house team pushing the boundaries of innovation" },
                    { title: "Creative Core", content: "Creativity at our core, ensuring a brand experience that is both impactful and memorable" }
                ]
            }
        ]
    },
    {
        id: 'key-differentiators',
        title: 'Key',
        subtitle: 'Differentiators',
        components: [
            {
                type: 'list',
                items: [
                    { title: "In-House Innovation", content: "A fully self-sufficient tech and creative team" },
                    { title: "Dual Perspective", content: "Balancing business needs with a customer-first approach" },
                    { title: "Collaborative Synergy", content: "Fostering partnerships across industries for greater impact" },
                    { title: "Adaptable Solutions", content: "Customizable tech that evolves with business demands" }
                ]
            }
        ]
    },
    {
        id: 'target-markets',
        title: 'Target',
        subtitle: 'Markets',
        components: [
            {
                type: 'list',
                items: [
                    { title: "Hospitality", content: "Nightclubs, bars, and restaurants turning transactions into lasting relationships" },
                    { title: "Events & Artists", content: "Tools to amplify experiences and build communities" },
                    { title: "Retail & Beauty", content: "Loyalty-driven commerce enhancing customer journeys" },
                    { title: "Corporate & Niche Markets", content: "Connecting brands to targeted audiences for meaningful engagement" }
                ]
            }
        ]
    },
    {
        id: 'revenue-streams',
        title: 'Revenue',
        subtitle: 'Streams',
        description: 'Nocturnal Lifestyle & Technology Ltd offers a ground-floor opportunity to invest in the future of commerce and experiential engagement.',
        components: [
            {
                type: 'stats',
                items: [
                    { value: '8-12%', label: 'Booking Fees per transaction' },
                    { value: '3-5%', label: 'Membership Subscriptions' },
                    { value: '£5', label: 'Monthly Student Subscriptions' },
                    { value: '0.6% + 20p', label: 'Nocturnal Pay Transaction Fee' }
                ]
            }
        ]
    },
    {
        id: 'investment-opportunity',
        title: 'Investment',
        subtitle: 'Opportunity',
        components: [
            {
                type: 'paragraph',
                text: 'Nocturnal Lifestyle & Technology Ltd offers a ground-floor opportunity to invest in the future of commerce and experiential engagement.'
            },
            {
                type: 'paragraph',
                text: 'Early-stage success with local event brands showcases our potential for exponential growth.'
            },
            {
                type: 'stats',
                items: [
                    { value: '£36,300', label: 'Gross Volume' },
                    { value: '£8,236', label: 'Net Volume' }
                ]
            },
            {
                type: 'subtitle',
                text: 'Timeline'
            },
            {
                type: 'list',
                items: [
                    { title: "Platform Launch", content: "December 1, 2024" },
                    { title: "Nationwide Rollout", content: "February 2025" }
                ]
            },
            {
                type: 'subtitle',
                text: 'Investment Allocation'
            },
            {
                type: 'list',
                items: [
                    { title: "Tech Expansion", content: "Building a world-class development team" },
                    { title: "Marketing", content: "Driving brand awareness and user acquisition" },
                    { title: "Feature Development", content: "Innovating with AI and machine learning" },
                    { title: "Operational Growth", content: "Expanding to new markets and verticals" }
                ]
            }
        ]
    },
    {
        id: 'growth-strategy',
        title: 'Growth',
        subtitle: 'Strategy',
        components: [
            {
                type: 'list',
                items: [
                    { title: "Market Expansion", content: "From Birmingham to a nationwide presence by 2025" },
                    { title: "Strategic Partnerships", content: "Aligning with major brands and event organizers" },
                    { title: "Continuous Innovation", content: "Leveraging feedback to stay ahead of market demands" },
                    { title: "Cross-Industry Expansion", content: "Beyond entertainment, into retail, beauty, and hospitality" }
                ]
            }
        ]
    }
];

const sectionTemplateMap = {
    generic: SectionTemplate,
    // We could still add specific templates if needed
};

const InvestmentProposal = () => {
    const [currentSection, setCurrentSection] = useState(0);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [touchStart, setTouchStart] = useState(0);
    const [totalSections, setTotalSections] = useState(0);
    const { openDrawer } = useDrawer();

    const navigationItems = useMemo(() => [
        {
            icon: 'IoHomeSharp',
            name: 'Home',
            destination: () => {
                const section = document.getElementById('hero');
                section?.scrollIntoView({ behavior: 'smooth' });
            },
            position: 1,
        },
        {
            icon: 'IoCashSharp',
            name: 'Revenue',
            destination: () => {
                const section = document.getElementById('revenue-streams');
                section?.scrollIntoView({ behavior: 'smooth' });
            },
            position: 3,
        },
        {
            icon: 'IoBarChartSharp',
            name: 'Invest',
            destination: () => openDrawer(<Invest />),
            position: 4,
        },
        {
            icon: 'IoMenuSharp',
            name: 'Menu',
            destination: () => openDrawer(
                <SlideOutMenu />
            ),
            position: 5,
        }
    ], [openDrawer]);

    useEffect(() => {
        const sections = document.querySelectorAll('[name="section"]').length;
        setTotalSections(sections);
    }, []);

    // Memoize handlers to prevent recreation on each render
    const handleTouchStart = useCallback((e) => {
        setTouchStart(e.touches[0].clientY);
    }, []);

    const handleTouchMove = useCallback((e) => {
        if (!touchStart) return;

        const yDiff = touchStart - e.touches[0].clientY;
        if (Math.abs(yDiff) > 500) {
            setIsRefreshing(true);
            setTimeout(() => setIsRefreshing(false), 1500);
        }
    }, [touchStart]);

    const scrollToSection = useCallback((index) => {
        const sections = document.querySelectorAll('[name="section"]');
        sections[index]?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    // Memoize swipe handlers
    const handlers = useSwipeable({
        onSwipedUp: () => currentSection < totalSections - 1 && scrollToSection(currentSection + 1),
        onSwipedDown: () => currentSection > 0 && scrollToSection(currentSection - 1),
        delta: 50,
        preventScrollOnSwipe: true,
        trackTouch: true,
        trackMouse: false
    });

    // Memoize intersection observer setup
    useEffect(() => {
        const observers = [];
        const sections = document.querySelectorAll('[name="section"]');

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = Array.from(sections).indexOf(entry.target);
                    setCurrentSection(index);
                }
            });
        };

        const observerOptions = {
            threshold: 0.5,
            rootMargin: '0px'
        };

        sections.forEach((section) => {
            const observer = new IntersectionObserver(observerCallback, observerOptions);
            observer.observe(section);
            observers.push(observer);
        });

        return () => {
            observers.forEach(observer => {
                observer.disconnect();
            });
        };
    }, []);

    return (
        <Container
            {...handlers.handlers}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >

        <FloatingNav items={navigationItems} />

   

            <ProgressBar>
                {[...Array(totalSections)].map((_, index) => (
                    <ProgressDot
                        key={index}
                        active={currentSection === index}
                        onClick={() => scrollToSection(index)}
                    />
                ))}
            </ProgressBar>

            <Section
                name='section'
                id='intro'
                as={motion.section}
                {...fadeInVariant}
                style={{ justifyContent: 'center', padding: '0' }}
            >
                <IntroSection
                    logo={'https://media.nctrnl.app/assets/nocturnal-logo.png'}
                    title={'Nocturnal Lifestyle'}
                    subtitle={'The Commerce Experience Engine'}
                    guestRole={'Black Friday'}
                    backgroundImage={'/background.jpeg'}
                    bottomContent={ <VideoComponent url={'https://media.nctrnl.app/videos/Nocturnal-Company-Reveal.MP4'} style={{ overflow: 'none', borderRadius: '20px' }} />}
                />
            </Section>

            {sections.map((section, index) => {
                const Template = sectionTemplateMap.generic;
                return <Template key={index} {...section} />;
            })}
        </Container>
    );
};

export default React.memo(InvestmentProposal);
