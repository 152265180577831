import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {ImpactTitle, Paragraph} from "../../../components/ui/typography/index.js";
import {CardFooter, CardHeader, MembersCardComponent, ScrollContainer, TierCard, TierSection} from "./CardNew.jsx";
import {AuthContext} from "../../authentication/index.js";
import {setSessionStorageJSON} from "../../../js/Helper.js";
import styled from "styled-components";
import {StandardSection} from "../../../layout/sections/StandardSection.jsx";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {DividerWithText} from "../../../components/ui/LogoWall/DividerWithText.jsx";
import {HtmlTextDiv} from "../components/CardPageContentComponent.jsx";
import {useDrawer} from "../../../js/hooks/context/DrawerContext.jsx";
import {FloatingNav} from "../../../layout/components/FloatingNav/index.jsx";
import {CARD_ICON, IMAGE_ICON, LOCATION_ICON, REWARD_ICON, USER_ICON} from "../../../js/icons.js";
import {PackForm} from "../../packs/components/index.js";
import {useNavigate, useParams} from "react-router-dom";
import {useCard} from "../js/index.js";
import {Loading} from "../../../components/ui/Loading.jsx";
import {extractColorCodeFromCssVariable} from "../../themes/js/utils.js";
import {VideoComponent} from "../../../components/ui/VideoComponent.jsx";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //padding-bottom: 70px;

 

`
const HeroSection = styled.section`
    display: inherit;
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
    height: ${props => props.height};
    gap: 20px;
    background-image: ${props => props.backgroundImage};
    background-size: cover;
    z-index: 10;
    padding-top: 40px;
    
    
    // Pseudo-element for gradient overlay
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: ${props => props.height};
        background: linear-gradient(to top, var(--primary-colour) 15%, transparent 100%);
        z-index: 0; // Ensure the overlay is above the image
        pointer-events: none; // Allows interaction with underlying elements
    }
`


// Container for the benefits section
const BenefitsSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    gap: 10px;
    justify-content: center;
    width: 100%;
`;

// BenefitsList component for the container
const BenefitsList = styled.div`
    display: flex;
    flex-wrap: wrap;

    gap: 10px; // Space between each benefit item
    width: 100%; // Ensure it takes up full width
    max-width: 600px; // Limit the width to 600px
    align-self: center; // Center the list
    justify-self: center;
`;

// BenefitItem component for each item
const BenefitItem = styled.div`
    flex: 1 1 105px; // Allows item to grow, shrink and have a base width of 140px
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: normal;
    display: flex;
    padding: 15px 30px;
    border-radius: var(--border-radius-default);
    font-size: 0.7rem;
    color: var(--text-colour); // Dark text color
    width: fit-content;
    
    //min-width: 50px; // Minimum width to maintain readability
    max-height: 50px;
    height: fit-content;
    min-height: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    text-transform: uppercase; // Uppercase text as shown in the image
    font-weight: bold;
    transition: all 0.3s ease; // Smooth transition for resizing
`;

// Move these constant menu items outside the component


const galleryMenuItem = {
  name: 'Gallery',
  destination: `${window.location.pathname}?section=gallery`,
  icon: IMAGE_ICON,
  position: 1,
};


const benefitsMenuItem = {
  name: 'Benefits',
  destination: `${window.location.pathname}?section=benefits`,
  icon: REWARD_ICON,
  position: 2,
};

const locationsMenuItem = {
  name: 'Locations',
  destination: `${window.location.pathname}?section=locations`,
  icon: LOCATION_ICON,
  position: 3,
};

const tiersMenuItem = {
  name: 'Tiers',
  destination: `${window.location.pathname}?section=tiers`,
  icon: CARD_ICON,
  position: 4,
};

const CardNew2 = ({ card, activeTier, feature, setActiveTier}) => {

    const { user } = useContext(AuthContext);
    const { openDrawer, closeDrawer, isOpen } = useDrawer();
    const { selectedTier, setSelectedTier } = useState(activeTier)
    const scrollRef = useRef(null);
    const navigate = useNavigate();
    const { member, setMember } = useCard();
    const params = useParams();
    const subPage = params?.subPage;

    useEffect(() => {
        const scrollContainer = scrollRef.current;

        // Function to handle scroll event
        const handleScroll = () => {
            // Calculate the center of the scroll container
            const centerX = scrollContainer.scrollLeft + scrollContainer.offsetWidth / 2;

            // Find the closest tier to the center
            const closestTier = Array.from(scrollContainer.children).reduce(
                (closest, tier, index) => {
                    const rect = tier.getBoundingClientRect();
                    const tierCenterX = rect.left + rect.width / 2;
                    const offset = Math.abs(centerX - tierCenterX);

                    if (offset < closest.offset) {
                        return { index, offset };
                    }
                    return closest;
                },
                { index: -1, offset: Infinity }
            );

            // Set the selected tier based on the closest tier
            setSelectedTier(card?.tiers[closestTier.index]);
        };

        // Add scroll event listener
        scrollContainer?.addEventListener("scroll", handleScroll);

        // Clean up the event listener on component unmount
        return () => scrollContainer?.removeEventListener("scroll", handleScroll);
    }, [card?.tiers]);

    const BENEFIT_TEXT = card.benefit_text ?? 'Benefits';
    const LOCATION_TEXT = card.location_text ?? 'Locations';
    const BUTTON_SIGNUP_TEXT = card.button_text ?? 'Sign Up';

    const hasLocations = card?.tiers?.some(tier => tier.locations.length > 0);
    const hasBenefits = card?.tiers?.some(tier => tier.benefits.length > 0);
    const hasTiers = card?.tiers?.length > 1;

    const heroBackground = activeTier?.files?.length > 0 ? activeTier?.files?.find((file) => file?.name === 'HeroBackground')['file']  :  null;

    const baseMenuItems = [
        {
            name: 'Signup',
            destination: () => handleSignupClick(),
            icon: USER_ICON,
            position: 5,
        },
    ];

    // Use useMemo to create the menu items array only when necessary
    const menuItems = useMemo(() => {
      const items = [...baseMenuItems];
      if (hasBenefits) items.push(benefitsMenuItem);
      if (hasLocations) items.push(locationsMenuItem);
      if (hasTiers) items.push(tiersMenuItem);
      return items.sort((a, b) => a.position - b.position);
    }, [hasBenefits, hasLocations, hasTiers]);



    const inputs = [
        {name: 'full_name', type: 'text', label: 'Full Name', placeholder: 'Jack Black', autoCorrect: 'full_name', required: true},
        {name: 'email', type: 'text', label: 'Email', placeholder: 'j.black@yourbusiness.com', autoCorrect: 'email', required: true},
        {name: 'date_of_birth', type: 'date', label: 'Date of Birth', placeholder: 'DD/MM/YYYY', autoCorrect: 'dob', required: true},
        {name: 'city', type: 'text', label: 'City', placeholder: 'Manchester', autoCorrect: 'city', required: true},
        {name: 'job', type: 'text', label: 'Job Role', placeholder: 'Waiter', autoCorrect: 'job', required: true},
        // {name: 'tiktok', type: 'text', label: 'Tik Tok', placeholder: '@j.black', autoCorrect: 'tiktok'},
        {name: 'instagram', type: 'text', label: 'Instagram', placeholder: 'j.black (without the @)', autoCorrect: 'instagram', required: true},
        {name: 'tier', type: 'hidden', value: activeTier?.id},
        {name: 'card', type: 'hidden', value: card?.id},
        {name: 'drawerIsOpen', type: 'hidden', isOpen},
    ]

    const partnerInputs = [
        {name: 'full_name', type: 'text', label: 'Your Full Name', placeholder: 'Jack Black', autoCorrect: 'full_name', required: true},
        {name: 'business_name', type: 'text', label: 'Business Name', placeholder: 'Austins Caribbean Experience', autoCorrect: 'business_name', required: true},
        {name: 'business_email', type: 'text', label: 'Your Email', placeholder: 'j.black@yourbusiness.com', autoCorrect: 'business_email', required: true},
        {name: 'job', type: 'text', label: 'Job Role', placeholder: 'General Manager', autoCorrect: 'job', required: true},
        {name: 'tier', type: 'hidden', value: activeTier?.id},
        {name: 'card', type: 'hidden', value: card?.id},
        {name: 'drawerIsOpen', type: 'hidden', isOpen},
    ];


    const handleBenefitClick = (benefit) => {
        openDrawer(
            <StandardSection>
                <HtmlTextDiv dangerouslySetInnerHTML={{ __html: benefit.description }} />
            </StandardSection>, benefit.name);
    };

    const onSuccess = (data, variables, context) => {

        setMember(data.data)
        setSessionStorageJSON('member', data.data)

        if(data?.next_actions?.redirect) {
            closeDrawer();
            navigate(data?.next_actions?.redirect)
        }
    }


    const handleSignupClick = () => {
        openDrawer(<PackForm
            inputs={data.inputs}
            endpointUrl={data.endpoint}
            onSuccess={onSuccess}
            name={'card-signup'}
        />)
    }

    const handleTierClick = (tier, index) => {
        setSelectedTier(tier);

        // Scroll the clicked tier into view
        const scrollContainer = scrollRef.current;
        const tierElement = scrollContainer.children[index];

        tierElement.scrollIntoView({
            behavior: 'smooth',  // Enable smooth scrolling
            block: 'nearest',    // Align the nearest side of the tier to the scroll container's nearest edge
            inline: 'center',    // Center the tier horizontally in the view
        });
    };

    let data;
    let benefits;

    switch(subPage) {
        case 'partners':
            benefits = activeTier?.benefits?.filter(benefit => benefit.isForPartner === true) ?? [];
            data = {
                card,
                subTitle: 'Become a partner of the',
                video: activeTier?.files?.find(file => file.name === 'DefaultVideo')?.file?.cdnUrl,
                cardDescription: card?.partner_description ?? card?.description,
                tiers: card?.tiers,
                activeTierDescription: activeTier?.partner_description ?? activeTier?.description,
                benefits: benefits,
                locations: activeTier?.locations,
                hasLocations: activeTier?.locations?.length > 0,
                inputs: partnerInputs,
                hasBenefits: benefits.length > 0,
                endpoint: 'member/create/partner'
            };
            break;
        default:
            benefits = activeTier?.benefits?.filter(benefit => benefit.isForPartner === false || benefit.isForPartner === undefined) ?? [];
            data = {
                card,
                video: activeTier?.files?.find(file => file.name === 'DefaultVideo')?.file?.cdnUrl,
                subTitle: 'Introducing The',
                cardDescription: card?.description,
                tiers: card?.tiers,
                activeTierDescription: activeTier?.description,
                benefits: benefits,
                locations: activeTier?.locations,
                hasLocations: activeTier?.locations?.length > 0,
                hasBenefits: benefits.length > 0,
                inputs: inputs,
                endpoint: 'member/create'
            };
            break;
    }

    const sectionStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        maxWidth: '600px',
    }

    if(!card || !activeTier) return <Loading/>;

    return (
        <PageContainer >
            <HeroSection id={'hero-section'} backgroundImage={`url(${heroBackground?.cdnUrl})`} height={heroBackground?.cdnUrl ? '90dvh' : 'fit-content'}>
                <MembersCardComponent
                    cardName={card.name}
                    selectedTier={activeTier}
                    feature={feature}
                    userName={ user ? `${user?.first_name} ${user?.last_name}` : 'Joe Blake' }
                    points={activeTier.initial_value}
                    image={activeTier?.image?.cdnUrl}
                ></MembersCardComponent>
                    <ImpactTitle header={data.subTitle} main={card?.name}  />
                    {/*<HtmlTextDiv style={{zIndex: 2}} dangerouslySetInnerHTML={{ __html: card.description}} />*/}

            </HeroSection>


            { activeTier.id === 115 &&
            <StandardSection style={{ background: 'rgba(0,0,0,0.2)',justifyContent: 'center'}}>
                <HtmlTextDiv style={{textAlign: 'justify', alignSelf: 'center', color: 'red'}} dangerouslySetInnerHTML={{ __html: data?.cardDescription }} />
                { data.video && <VideoComponent url={data.video}  /> }
            </StandardSection>
            }


            <StandardSection id={'tier'} style={sectionStyle}>
                {/*<DefaultGallery galleryId={card.id === 82 || card.id === 87 ? 1 : null} />*/}
                { hasTiers && <DividerWithText text={activeTier.name} />}
                <HtmlTextDiv style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html: data.activeTierDescription }} />
            </StandardSection>


            { data.hasBenefits && <StandardSection id={'benefits'} syle={sectionStyle}>
                <DividerWithText text={BENEFIT_TEXT?? 'Benefits'} />
                <BenefitsSectionContainer>
                    <BenefitsList>
                        {data?.benefits?.map((benefit, index) => (
                            <BenefitItem
                                key={index}
                                onClick={() => handleBenefitClick(benefit)}
                                style={{border: `solid 1px ${extractColorCodeFromCssVariable('--accent-colour')}`}}

                            >
                                {benefit.name}
                            </BenefitItem>
                        ))}
                    </BenefitsList>
                </BenefitsSectionContainer>
            </StandardSection>}

            {/*{ data.hasLocations && <StandardSection id={'locations'} syle={sectionStyle}>*/}
            {/*        <DividerWithText text={LOCATION_TEXT ?? 'Locations'} />*/}
            {/*        <BenefitsSectionContainer>*/}
            {/*            <BenefitsList>*/}
            {/*                {data?.locations?.map((benefit, index) => (*/}
            {/*                    <BenefitItem*/}
            {/*                        key={index}*/}
            {/*                        onClick={() => handleBenefitClick(benefit)}*/}
            {/*                        style={{border: `solid 1px ${extractColorCodeFromCssVariable('--accent-colour')}`}}*/}

            {/*                    >*/}
            {/*                        {benefit.name}*/}
            {/*                    </BenefitItem>*/}
            {/*                ))}*/}
            {/*            </BenefitsList>*/}
            {/*        </BenefitsSectionContainer>*/}
            {/*    </StandardSection>*/}
            {/*}*/}





             <FloatingNav items={menuItems} />



            { card?.tiers?.length > 1 && <TierSection className={'background-pattern'}>
                <ScrollContainer ref={scrollRef} id={'tiers'} >
                    {card?.tiers?.map((tier, index) => {
                        return (
                            <TierCard
                                image={tier?.image?.cdnUrl}
                                key={index}
                                isSelected={activeTier.id === tier.id} // Determine if this tier is the selected one
                                onClick={() => handleTierClick(tier, index)} // Updated to include index for scrolling
                            >
                                <CardHeader>
                                    <div>{tier?.card?.name}</div>
                                    <div>{tier.name}</div>
                                </CardHeader>
                                <CardFooter>
                                    <div>{tier.userName}</div>
                                    <div>{tier.points}</div>
                                </CardFooter>
                            </TierCard>
                        )})}
                </ScrollContainer>
            </TierSection>}


            <div style={{width: '100vw', padding: '20px', display: 'flex', justifyContent: 'center'}}>
                <ButtonComponent
                    variant={'solid'}
                    text={BUTTON_SIGNUP_TEXT?? 'Sign Up'}
                    onClick={() => handleSignupClick()}
                />
            </div>




        </PageContainer>
    );
};

export default CardNew2;


const BenefitsSection = () => {
    return (
<></>
    )
}