import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LogoComponent from '../../components/ui/LogoComponent';
import {InstructionTitle, Paragraph} from "../../components/ui/typography/index.js";
import {PackForm} from "../../features/packs/components/index.js";

// #region constants

// #endregion

// #region styled-components
const HeaderContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100svh;
    min-height: 100svh;
    padding: 40px;
    background: url('/background.jpeg') no-repeat center center;
    background-size: cover;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(to top, var(--primary-colour) 10%, transparent 100%);
    }
`
// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

/**
 * 
 */

const inputs = [
    {
        type: 'text',
        name: 'name',
        placeholder: 'Full Name',
        required: true,
    },
    {
        type: 'email',
        name: 'email',
        placeholder: 'Email',
        required: true,
    },
    {
        type: 'text',
        name: 'instagram',
        placeholder: 'Instagram',
        required: false,
    },
    {
        type: 'text',
        name: 'company',
        placeholder: 'Company (optional)',
        required: false,
    },

    {
        type: 'hidden',
        name: 'campaign_id',
        value: 5
    },
    {
        type: 'hidden',
        name: 'form_id',
        value: 1
    }
]
const Header = () => {
    return (
        <HeaderContainer id={'header'}>
            <LogoComponent customVariant={'gold'} />
            <PackForm name={'form'} inputs={inputs} endpointUrl={'campaign-submissions'} />
        </HeaderContainer>
    )
}

Header.propTypes = propTypes;
// #endregion

export default Header;
