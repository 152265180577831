import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import {object} from 'prop-types'
import {ButtonComponent} from "../../../../components/ui/buttons/index.js";
import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import Basket from "../../basket/index.js";
import {useEvent} from "../../../events/js/hooks/index.js";
import {useProduct} from "../hooks/index.js";
import {Addons} from "./index.js";
import {StandardSection} from "../../../../layout/sections/StandardSection.jsx";
import {HowManyPeople} from "../../components/HowManyPeople/HowManyPeople.jsx";
import {VideoComponent} from "../../../../components/ui/VideoComponent.jsx";
import {useHandleError} from "../../../error-handling/js/hooks/index.js";

const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100dvh;
    max-width: 100vw;
    overflow: hidden;
`

const ScrollableContent = styled.div`
    flex: 1;
    overflow-y: auto;
    padding-bottom: 120px;
    -webkit-overflow-scrolling: touch;
`

const FixedButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    padding: 60px 16px 16px;
    background: linear-gradient(to top, var(--primary-colour), transparent 100%);
    z-index: 100;
`

const ProductDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--text-colour);
    padding-bottom: 20px;
    text-align: left;
    
    h3 {
        text-align: center;
        color: var(--accent-colour);
        background: var(--accent-colour);
        font-size: 1.2rem;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const DepositContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 100%;

`

export const Product = ({ product, callToAction }) => {
    if (!product) {
        console.error('Product component received null/undefined product');
        return <div>Error: Product not found</div>;
    }

    const [selectedAddons, setSelectedAddons] = useState([]);
    const { event } = useEvent();
    const { addToBasket } = useEcommerce();
    const { closeDrawer, openDrawer } = useDrawer();
    const { addDependenciesToBasket } = useProduct();
    const { showErrorToast } = useHandleError();

    const [paymentOption, setPaymentOption] = useState('full');
    const [numberOfPeople, setNumberOfPeople] = useState(product.min_quantity || 1);
    const [loading, setLoading] = useState(false);



    const hasCatalogue = product?.catalogues?.length > 0;

    useEffect(() => {
        try {
            handleNumberOfPeople();
        } catch (err) {
            console.error('Error updating number of people:', err);
            showErrorToast('Error updating quantity');
        }
    }, [numberOfPeople]);

    const openBasket = () => {
        try {
            openDrawer(<Basket event={event} />, 'Basket', innerWidth > 768 ? 'right' : 'bottom');
        } catch (err) {
            console.error('Error opening basket:', err);
            showErrorToast('Unable to open basket');
        }
    };

    const handleAddonToggle = (addon) => {
        setSelectedAddons(prev => {
            const exists = prev.find(a => a.id === addon.id);
            if (exists) {
                return prev.filter(a => a.id !== addon.id);
            } else {
                const newAddon = {
                    ...addon,
                    quantity: numberOfPeople,
                    parentProductId: product.id
                };
                return [...prev, newAddon];
            }
        });
    };

    const handleClick = async () => {
        try {
            setLoading(true);

            // Create a copy of the product with its selected addons
            const productWithAddons = {
                ...product,
                quantity: numberOfPeople,
                paymentOption,
                addons: selectedAddons
            };

            // Add to basket
            await addToBasket(productWithAddons, numberOfPeople);
            await addDependenciesToBasket(product, numberOfPeople);
            
            closeDrawer();
            openBasket();
        } catch (err) {
            console.error('Error adding product to basket:', err);
            showErrorToast(err.message || 'Error adding product to basket');
        } finally {
            setLoading(false);
        }
    };

    const handleNumberOfPeople = () => {
        if (!product || typeof numberOfPeople !== 'number') {
            console.warn('Invalid input for price calculation');
            return;
        }
        product.sub_total = product.price * numberOfPeople;
    };

    const callToActionText = callToAction?.text ?? 'Add To Basket';
    const callToActionFn = () => {
        (callToAction?.function ?? handleClick)();
    };

    const files = product.file_links ?? [];
    const hasFiles = files && files.length > 0;
    const video = hasFiles ? files.find(file => file.file.category === 'videos') : null;

    return (
        <Container>
            <ScrollableContent>
                <StandardSection style={{maxWidth: '100%'}}>
                    {video && <VideoComponent data={video.file} />}

                    {product.description && (
                        <ProductDescription
                            dangerouslySetInnerHTML={{ __html: product.description }}
                        />
                    )}

                    {product.category === 'tableBooking' && (
                        <HowManyPeople 
                            product={product} 
                            numberOfPeople={numberOfPeople} 
                            setNumberOfPeople={setNumberOfPeople} 
                            style={{marginBottom: '20px'}} 
                        />
                    )}
                </StandardSection>

                <StandardSection>
                    <Addons 
                        product={product}
                        selectedAddons={selectedAddons}
                        onAddonToggle={handleAddonToggle}
                        numberOfPeople={numberOfPeople}
                    />
                </StandardSection>
            </ScrollableContent>

            <FixedButtonContainer>
                <ButtonComponent
                    variant={'solid'}
                    text={callToActionText}
                    onClick={callToActionFn}
                    isLoading={loading}
                    isDisabled={loading}
                    style={{
                        width: '100%',
                        height: '40px'
                    }}
                />
            </FixedButtonContainer>
        </Container>
    );
};

Product.propTypes = {
    product: object
}