import React, {useContext, useEffect, useState} from "react";
import {useDrawer} from "../../../../../../js/hooks/context/DrawerContext.jsx";
import {useEcommerce} from "../../../../js/context/EcommerceContext.jsx";
import {useCheckout} from "../../../../js/context/CheckoutContext.jsx";
import useHandleClickCheckout from "../../../../js/hooks/useHandleClickCheckout.jsx";
import {AuthContext} from "../../../../../authentication/index.js";
import {toast} from "react-toastify";
import axios from "axios";
import {API_URL} from "../../../../../../js/Helper.js";
import PackForm from "../../../../../packs/components/PackForm.jsx";
import {DividerWithText} from "../../../../../../components/ui/LogoWall/DividerWithText.jsx";
import {
    MetaItem,
    PriceTag,
    ProductDescription,
    ProductInfoContainer,
    ProductMetaContainer, StockIndicator
} from "../js/productPageInfo.styles.js";
import ImpactTitle from "../../../../../../components/ui/typography/ImpactTitle.jsx";
import {
    DiscountedPrice,
    OfferCard,
    OfferDescription,
    OfferName,
    OffersScrollContainer,
    OffersSection, OfferValue, OriginalPrice, PriceDisplay
} from "../../offers/js/productOffers.styles.js";
import {FaCheckCircle, FaTag, FaTimesCircle} from "react-icons/fa";
import {convertToCurrency} from "../../../../../../js/helpers/helpers.js";
import {Paragraph} from "../../../../../../components/ui/typography/index.js";
import {Divider} from "../../../../../../components/ui/Divider.jsx";
import {ButtonComponent} from "../../../../../../components/ui/buttons/index.js";
import {IoCartOutline} from "react-icons/io5";
import StripePaymentForm from "../../../../services/stripe/components/StripePaymentForm.jsx";
import Section from "../../../../orders/components/Section.jsx";
import {StandardSection} from "../../../../../../layout/sections/StandardSection.jsx";
import {useHandleError} from "../../../../../error-handling/js/hooks/index.js";

/**
 * Product Info Section
 * @param {*} product
 * @returns
 */
export const ProductInfoSection = ({ product, offers }) => {
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [paymentIntent, setPaymentIntent] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const { openDrawer } = useDrawer();
    const { showErrorToast } = useHandleError();
    const { addToBasket, basket, setConfig, emptyBasket } = useEcommerce();
    const {
        checkout,
        setCheckout,
        setTotal,
        setFees,

        setStripe,
        setQuestions,
        checkoutQuestions
    } = useCheckout();
    const { handleClickCheckout, checkoutLoading } = useHandleClickCheckout();
    const { user, setUser, isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        business_name: '',
        full_name: user ? `${user.first_name} ${user.last_name}` : '',
        email: user?.email || ''
    });
    const [pendingCheckout, setPendingCheckout] = useState(false);

    const handleInputChange = (e) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    // Add this useEffect to watch for basket changes
    useEffect(() => {
        const processCheckout = async () => {

            if (pendingCheckout && basket.orderItems?.length > 0) {
                setPendingCheckout(false);
                setIsProcessing(true);


                const postData = {
                    full_name: formData.full_name,
                    email: formData.email,
                    product_id: product.id,
                    quantity: 1,
                    basket_id: basket.id,
                }

                axios.post(`${API_URL}/basket/checkout-single-product`, postData)
                    .then(response => {
                        const data = response.data;

                        setShowPaymentForm(true);
                    })
                    .catch(error => {
                        console.error('Error fetching checkout:', error);
                        showErrorToast(error ?? 'There was an error processing your request');
                        setIsProcessing(false);
                    });
            }
        };

        processCheckout();
    }, [basket, pendingCheckout]);

    const handleLockInOffer = () => {
        if (!selectedOffer) return;

        if(!formData.full_name || !formData.email) {
            toast.warn('Please fill in all required fields');
            return;
        }

        const { files, faqs, reviews, offers, ...productWithoutArrays } = product;


        productWithoutArrays.quantity = 1;
        productWithoutArrays.paymentOption = 'deposit';
        productWithoutArrays.offerId = selectedOffer.id;
        productWithoutArrays.sub_total = product.price;



        // Add the form data to the product object
        productWithoutArrays.customer_details = {
            business_name: formData.business_name,
            full_name: formData.full_name,
            email: formData.email
        };



        emptyBasket();

        addToBasket(productWithoutArrays, 1, selectedOffer);
        setPendingCheckout(true);
    };

    const handlePayment = async (stripe, elements) => {
        setIsProcessing(true);

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: "https://example.com/order/123/complete",
            },
        });

        if (result.error) {
            console.log(result.error.message);
            toast.error(result.error.message);
        } else {
            toast.success('Payment successful');
        }

        setIsProcessing(false);
    };

    const ProductEnquiry = () => {
        openDrawer(<PackForm
            name='Product Enquiry'
            endpointUrl='product-purchase-enquiries'
            inputs={
                [
                    {
                        name: 'full_name',
                        label: 'Full Name',
                        type: 'text',
                        placeholder: 'Enter your name',
                        required: true,
                        value: user ? `${user.first_name} ${user.last_name}` : undefined
                    },
                    {
                        name: 'email',
                        label: 'Email Address',
                        type: 'email',
                        placeholder: 'Enter your email',
                        required: true,
                        value: user?.email
                    },
                    {
                        name: 'phone',
                        label: 'Phone Number',
                        type: 'tel',
                        placeholder: 'Enter your phone number',
                        required: false,
                        value: user?.phone
                    },
                    { name: 'product_id', type: 'hidden', value: product.id },
                    {name: 'referrer', type: 'hidden', value: 'product-page'},
                    {name: 'quantity', type: 'hidden', value: 1},
                    { name: 'enquiry', label: 'Enquiry', type: 'textarea', placeholder: 'Provide us with some details about your enquiry', required: false }
                ]
            } />);
    }

    const showPaymentModal = () => {
        addToBasket(product, 1, selectedOffer);

    }

    const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
        const discountAmount = originalPrice * (discountPercentage / 100);
        return originalPrice - discountAmount;
    }

    const handleOfferClick = (offer) => {
        setSelectedOffer(offer);
    }

    const discountedPrice = selectedOffer
        ? calculateDiscountedPrice(product.price, selectedOffer.value)
        : null;


    return (
        <>
            {/* <InstructionTitle title={'Product Information'} style={{marginBottom: '-25px', zIndex: 1, margin: '0 auto', textTransform: 'uppercase'}} /> */}

            <ProductInfoContainer id="info">
                {/* <ProductName>{product.name}</ProductName> */}
                <ImpactTitle subHeader={product?.business?.name} main={product?.name} />

                <ProductDescription id='description' dangerouslySetInnerHTML={{ __html: product.description }} />
            </ProductInfoContainer>

            {offers && offers.length > 0 && (
                <OffersSection id='offers'>
                    <DividerWithText
                        text='Available Offers'
                        style={{
                            marginBottom: '30px',
                            textTransform: 'uppercase',
                            position: 'sticky',
                            top: 0,
                            background: 'var(--primary-colour)',
                            zIndex: 1
                        }}
                    />
                    <OffersScrollContainer>
                        {offers.sort((a, b) => b.value - a.value).map((offer) => (
                            <OfferCard
                                key={offer.id}
                                onClick={() => handleOfferClick(offer)}
                                isSelected={selectedOffer && selectedOffer.id === offer.id}
                            >
                                <OfferName isSelected={selectedOffer && selectedOffer.id === offer.id}>
                                    {offer.name}
                                </OfferName>
                                <OfferDescription isSelected={selectedOffer && selectedOffer.id === offer.id}>
                                    {offer.description}
                                </OfferDescription>
                                <OfferValue isSelected={selectedOffer && selectedOffer.id === offer.id}>
                                    {offer.value}% OFF
                                </OfferValue>
                                <OfferDescription>
                                    {offer?.redemptionsLeft} Available
                                </OfferDescription>
                            </OfferCard>
                        ))}
                    </OffersScrollContainer>
                </OffersSection>
            )}

            <ProductInfoContainer>
                <ProductMetaContainer id='meta'>
                    <PriceDisplay>
                        <PriceTag>
                            <FaTag />
                            <OriginalPrice hasDiscount={!!selectedOffer}>
                                {convertToCurrency(product.price)}
                            </OriginalPrice>
                            {discountedPrice && (
                                <DiscountedPrice>
                                    {convertToCurrency(discountedPrice)}
                                </DiscountedPrice>
                            )}
                        </PriceTag>
                    </PriceDisplay>
                    <MetaItem>
                        {product.remaining > 0 ? <FaCheckCircle color="var(--success-colour)" /> : <FaTimesCircle color="var(--error-colour)" />}
                        <StockIndicator id='stock' inStock={product.remaining > 0}>
                            {product.remaining > 0 ? 'Available' : 'Unavailable'}
                        </StockIndicator>
                    </MetaItem>
                </ProductMetaContainer>
                {/*{*/}
                {/*    selectedOffer && product.remaining > 0 && (*/}
                {/*        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '20px'}}>*/}
                {/*            <Paragraph>Lock in this price and secure your <strong>{selectedOffer.name}</strong> offer by paying a deposit of<strong> only {convertToCurrency(discountedPrice / 2)}</strong> now.</Paragraph>*/}

                {/*            {!showPaymentForm ? (*/}
                {/*                <>*/}
                {/*                    <Divider />*/}
                {/*                    <StandardSection>*/}
                {/*                        <input*/}
                {/*                            type="text"*/}
                {/*                            name="business_name"*/}
                {/*                            placeholder="Business Name"*/}
                {/*                            value={formData.business_name}*/}
                {/*                            onChange={handleInputChange}*/}
                {/*                            required*/}
                {/*                        />*/}
                {/*                        <input*/}
                {/*                            type="text"*/}
                {/*                            name="full_name"*/}
                {/*                            placeholder="Full Name"*/}
                {/*                            value={formData.full_name}*/}
                {/*                            onChange={handleInputChange}*/}
                {/*                            required*/}
                {/*                        />*/}
                {/*                        <input*/}
                {/*                            type="email"*/}
                {/*                            name="email"*/}
                {/*                            placeholder="Email Address"*/}
                {/*                            value={formData.email}*/}
                {/*                            onChange={handleInputChange}*/}
                {/*                            required*/}
                {/*                        />*/}
                {/*                    <Divider style={{marginBottom: '20px'}}/>*/}
                {/*                    <ButtonComponent*/}
                {/*                        id='lockOffer'*/}
                {/*                        text='Lock in Offer'*/}
                {/*                        icon={<IoCartOutline />}*/}
                {/*                        variant='solid'*/}
                {/*                        onClick={handleLockInOffer}*/}
                {/*                        disabled={isProcessing || !formData.business_name || !formData.full_name || !formData.email}*/}
                {/*                        isLoading={checkoutLoading}*/}
                {/*                    />*/}

                {/*                        {!showPaymentForm && (*/}
                {/*                            <ButtonComponent*/}
                {/*                                id='enquiry'*/}
                {/*                                text='Make Enquiry'*/}
                {/*                                icon={<IoCartOutline />}*/}
                {/*                                variant='outlined'*/}
                {/*                                onClick={() => ProductEnquiry()}*/}
                {/*                            />*/}
                {/*                        )}*/}

                {/*                    </StandardSection>*/}

                {/*                </>*/}
                {/*            ) : (*/}
                {/*                paymentIntent && (*/}
                {/*                    <StripePaymentForm*/}
                {/*                        payment_intent={paymentIntent}*/}
                {/*                        style={{padding: '50px 0', width: '100%'}}*/}
                {/*                        // theme={theme}*/}

                {/*                        onSuccess={() => toast.success('Payment successful')}*/}
                {/*                    />*/}
                {/*                )*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}

            </ProductInfoContainer>
        </>
    );
};