import {useEcommerce} from "../../../js/context/EcommerceContext.jsx";
import {useHandleError} from "../../../../error-handling/js/hooks/index.js";
import {useContext} from "react";
import {AuthContext} from "../../../../authentication/index.js";
import {API_URL, scrollToElement} from "../../../../../js/Helper.js";
import axios from "axios";

/**
 * Hook to manage Stripe payment processing
 * @returns {Object} Payment handling functions and utilities
 */
export const useHandleStripePayment = () => {
    const { updateConfig, basket } = useEcommerce();
    const { showErrorToast } = useHandleError();
    const { setUser, setIsAuthenticated, setToken } = useContext(AuthContext)

    /**
     * Validates if payment can proceed based on authentication status
     * @param {boolean} isPaymentAllowed - Flag indicating if payment is allowed
     * @param {boolean} isAuthenticated - User authentication status
     * @returns {boolean} True if payment can proceed
     * @throws {Error} If payment prerequisites are not met
     */
    const isPaymentAllowed = (isPaymentAllowed, isAuthenticated) => {
        if(isAuthenticated) return true;
        if(isPaymentAllowed) return true;

        scrollToElement('order-summary')
        throw new Error('Authentication required - please log in or complete your details.');
    }

    /**
     * Validates completion status of required questions
     * @param {boolean} hasQuestions - Flag indicating if there are required questions
     * @param {boolean} questionsComplete - Flag indicating if questions are completed
     * @returns {boolean} True if questions are completed or not required
     * @throws {Error} If required questions are incomplete
     */
    const hasCompletedQuestions = (hasQuestions, questionsComplete) => {
        if(hasQuestions === true && questionsComplete !== true) {
            scrollToElement('questions')
            throw new Error('Please complete all required questions before proceeding');
        }
        return true;
    }

    /**
     * Validates payment form elements
     * @param {Object} elements - Stripe Elements instance
     * @returns {Promise<boolean>} True if validation passes
     * @throws {Error} If form validation fails
     */
    const formValidation = async (elements) => {
        if (!elements) {
            throw new Error('Payment form not properly initialized');
        }

        const {error: submitError} = await elements.submit();
        if (submitError) {
            throw new Error(`Form validation error: ${submitError.message}`);
        }

        return true;
    }

    /**
     * Retrieves client secret for payment processing
     * @param {string} intent - Payment intent ID
     * @returns {Promise<Object>} Payment details including client secret
     * @throws {Error} If client secret cannot be retrieved
     */
    const getClientSecret = async (intent) => {

        // if(!intent) {
        //     console.error('Payment Intent must be provided')
        //     throw new Error("Payment Intent is required to process payment")
        // }

        try {
            const res = await axios.post(`${API_URL}/stripe/payment_intent/${intent ?? 0}/client_secret`, {
                basket_id: basket.id
            });

            if(!res.data) {
                throw new Error('Invalid response from payment server')
            }

            updateConfig({
                confirmationMessage: res.data.message,
                order: res.data.order
            })

            if(res.data.status === 'error') {
                throw new Error(res.data.message || 'Error processing payment')
            }

            return {
                clientSecret: res.data.client_secret,
                order: res.data.order,
                user: res.data.user,
                message: res.data.message
            }

        } catch (error) {
            console.error("Error fetching client secret:", error);
            const code = error.status ?? error.response?.status ?? error.code ?? error.response?.code ?? 500;

            if(code === 401) {
                setUser(null)
                setIsAuthenticated(false)
                setToken(null)
                throw new Error('Authentication required - please log in to complete payment');
            }

            if(code === 400) {
                throw new Error('Invalid payment request - please check your payment details');
            }

            if(code === 422) {
                throw new Error('Payment validation failed - please check your input');
            }

            const message = error.response?.data?.message ?? error?.statusText ?? error?.message ?? 'Error completing payment';
            throw new Error(`Payment error: ${message}`);
        }
    }

    /**
     * Confirms payment with Stripe
     * @param {string} clientSecret - Stripe client secret
     * @param {Object} order - Order details
     * @param {Object} user - User details
     * @param {Object} stripe - Stripe instance
     * @param {Object} elements - Stripe Elements instance
     * @returns {Promise<void>}
     * @throws {Error} If payment confirmation fails
     */
    const confirmPayment = async (clientSecret, order, user, stripe, elements) => {
        if (!stripe || !elements) {
            throw new Error('Payment system not properly initialized');
        }

        const {error} = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
                return_url: `${window.location.origin}/confirmation/${order.reference}`,
                payment_method_data: {
                    billing_details: {
                        name: `${user?.first_name} ${user?.last_name}`,
                        email: user?.email,
                    }
                },
            },
        });

        if (error) {
            console.error('Payment Attempt Failed:', error);
            showErrorToast(error)
            throw new Error(error.message || 'Payment confirmation failed');
        }
    }

    return {
        confirmPayment,
        formValidation,
        getClientSecret,
        isPaymentAllowed,
        hasCompletedQuestions,
    }
}
