import React, { useState } from 'react';
import styled from "styled-components";
import { ButtonComponent } from "../../../components/ui/buttons/index.js";
import { DividerWithText } from "../../../components/ui/LogoWall/DividerWithText.jsx";
import { useApiQuery } from '../../../hooks/customHooks.js';
import { updateTheme } from '../../themes/js/utils.js';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../components/ui/Loading.jsx';
import EventsSection from '../../proposals/components/EventsSection.jsx';
import { BACKGROUND_IMAGE, MAIN_IMAGE } from '../../moments/pages/CampaignPurchaseLandingPage.jsx';
import { StandardSection } from '../../../layout/sections/StandardSection.jsx';
import { InstructionTitle } from '../../../components/ui/typography/InstructionTitle.jsx';
import { CardScrollContainer, CardWrapper } from '../../proposals/components/CardSection.jsx';
import { LoyaltyCard } from '../../cards/components/LoyaltyCard/LoyaltyCard.jsx';
import influencerData from '../../proposals/pages/influencerData.js';
import { ImageComponent } from '../../../components/ui/images/ImageComponent.jsx';
import ImpactTitle from '../../../components/ui/typography/ImpactTitle.jsx';
import { toast } from 'react-toastify';
import { FaCopy } from 'react-icons/fa'; // Make sure to install react-icons if not already installed
import { Paragraph } from '../../../components/ui/typography/Paragraph.jsx';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: 100svh;
    max-width: 100vw;
    padding: 20px;
    gap: 20px;
    overflow-x: hidden;
`

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 100vw;
    padding: 20px;
`

const SplitButton = styled.div`
  display: flex;
  width: 80%;
  gap: 10px;
  max-width: 10vw
  margin-bottom: 10px;
//   border: 1px solid white;
  border-radius: var(--border-radius-default);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0px 10px;
  z-index: 1;
`;

const MainButton = styled(ButtonComponent)`
  flex-grow: 3;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: var(--colour-light);
`;

const CopyButton = styled.div`
  width: 1px;
  min-width: 20px;
  flex-grow: 1;
  border: none;
  max-width: 40px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinksPage = () => {
    const { slug } = useParams();
    const [campaign, setCampaign] = useState();

    const { data, isLoading, isError } = useApiQuery(`campaigns/${slug}?with=fileLinks,catalogueLinks.catalogue,theme,events`, (data) => {
        if(data.status !== 'success') console.log('error')
        const res = data.data;
        setCampaign(res)
        updateTheme(res.theme)
    });

    if(isLoading) return <Loading />
    if(isError) return <div>Error loading campaign</div>
    if(!campaign) return null;

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.success('Link copied to clipboard!');
    };

    const openLink = (url) => {
        window.open(url, '_blank');
    };

    const renderSplitButton = (text, link) => (
        <SplitButton>
            <MainButton 
                variant="tertiary" 
                text={text}
                onClick={() => openLink(link)}
            />
            <CopyButton
                variant="outlined"
                onClick={() => copyToClipboard(link)}
                style={{
                    maxWidth: '40px',
                }}
            >
                <FaCopy />
            </CopyButton>
        </SplitButton>
    );

    return (
        <PageContainer>
            <ImpactTitle main={campaign.name} header={'Links for'} />
            <ImageComponent src={campaign.files.find(file => file.name === MAIN_IMAGE)?.cdnUrl} width={'80%'} />

            <StandardSection>
                <Paragraph>
                    Below are all the links you will need to share with people. This page is mostly for internal use.
                </Paragraph>

                <Paragraph>
                    But you can also share the page for any person / business you wan to know EVERYTHING about the campaign.
                </Paragraph>

                <Paragraph><strong>Instructions:</strong> Click the text to direct to the page or click the copy button to copy the link.</Paragraph>
            </StandardSection>
     

            {campaign.events?.length > 0 && (
                <StandardSection>
                    <InstructionTitle title={'Event Links'} />
                    {campaign.events.map((event) => renderSplitButton(
                        event.name,
                        `${window.location.origin}/e/${event.slug}`
                    ))}

                    <DividerWithText text={'All Events Links'} style={{margin: '10px'}} />
                    {renderSplitButton('All Events', `${window.location.origin}/olife/events`)}
                </StandardSection>
            )}

            <StandardSection>
                <InstructionTitle title={'Sponsor Links'} />
                {renderSplitButton('View Sponsor Page', `${window.location.origin}/campaign/${slug}`)}
            </StandardSection>

            <StandardSection>
                <InstructionTitle title={'Influencer Links'} />
                {Object.keys(influencerData).map((influencerType) => renderSplitButton(
                    influencerType,
                    `${window.location.origin}/proposals/${influencerType}/${slug}`
                ))}
            </StandardSection>

            <InstructionTitle title={'Card Links'} style={{marginBottom: '-50px'}}/>
            <CardScrollContainer>
                {campaign.cards.map((card, index) => (
                    <CardWrapper key={index}>
                        <LoyaltyCard
                            card={card}
                            tier={card.default_tier}
                            onClick={() => openLink(`${window.location.origin}/c/${card.slug}`)}
                        />
                        <CopyButton
                            variant="outlined"
                            onClick={() => copyToClipboard(`${window.location.origin}/c/${card.slug}`)}
                        >
                            <FaCopy />
                        </CopyButton>
                    </CardWrapper>
                ))}
            </CardScrollContainer>
        </PageContainer>
    );
};

export default LinksPage;
